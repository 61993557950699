import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';

interface YesNoProps {
	onYes():void,
	onNo():void,
	title?:String,
	text:String,
	isOpen:boolean,
}

const YesNo:React.FC<YesNoProps> = (props)=> {
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={props.onNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
			  {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNo} color="primary">
            いいえ
          </Button>
          <Button onClick={props.onYes} color="primary" autoFocus>
            はい
          </Button>
        </DialogActions>
		<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
			<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.onNo}><CancelIcon/></IconButton></Tooltip>
		</DialogActions>
      </Dialog>
    </div>
  );
}

export default YesNo;