import React,{useState, useEffect} from 'react';
//import {Link} from 'react-router-dom'
import {useTheme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
//import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
//import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
//import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import AssistantIcon from '@material-ui/icons/Assistant';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import {Inc,Profile} from './Inc';
import Toast from './Toast';
import Ajax from './Ajax';
import Loading from './Loading';
import Tools from './Tools';
import {UserContainer} from './UserContainer';
import PhotoUpload from './PhotoUpload';

interface PublishProps {
	open:boolean,
	profile:Profile,
	update(profile:Profile):void,
	close():void,
}

const Settings:React.FC<PublishProps> = (props)=> {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userState = UserContainer.useContainer();
	const [states, setStates] = useState({
		profile:Inc.defaultProfile(),
		error:Inc.defaultProfileError(),
		isLoading: true,
		updated:false,
		toast:'',
		defaultViewUrl: "",
		photo: "",
	});
	//const [photos, setPhotos] = useState<File[]>([]);

	const putTextField = (label:string,key:keyof Profile,opt?:{[key:string]:any;})=>{
		let color:"primary"|"secondary"|undefined = "primary";
		if( opt && opt["color"] && (opt["color"]==="primary" || opt["color"]==="secondary") ){
			color = opt["color"];
		}
		let rows:number = 1;
		if( opt && opt["rows"] ){
			rows = opt["rows"];
		}
		let type:"text"|"time" = "text";
		if( opt && opt["type"] ){
			type = opt["type"];
		}

		let inputLabelProps:any = {}
		let inputProps:any = {}
		
		if( opt ){
			if( opt["shrink"]===true ){
				inputLabelProps["shrink"] = true;
			}
			if( opt["startAdornment"] ){
				inputProps["startAdornment"] = (
					<InputAdornment position="start">{opt["startAdornment"]}</InputAdornment>
				);
			}
			if( opt["endAdornment"] ){
				inputProps["endAdornment"] = (
					<InputAdornment position="end">{opt["endAdornment"]}</InputAdornment>
				);
			}
		}
		return (
			<TextField
				label={label}
				variant="outlined"
				fullWidth={true}
				onChange = {onChangeText(key)}
				value={states.profile[key]}
				error={states.error[key]!==""}
				helperText={states.error[key]}
				color={color}
				rows={rows}
				multiline={rows>1}
				InputLabelProps = {inputLabelProps}
				InputProps = {inputProps}
				type={type}
			/>
		)		
	}
	const putSelect = (label:string,key:keyof Profile,list:{[key:string]:string;})=>{
		const labelId = key + "-label";
		const id = key + "-select";
		return (
			<FormControl variant="outlined" fullWidth={true}>
				<InputLabel shrink>{label}</InputLabel>
				<Select
					label = {label}
					labelId={labelId}
					id={id}
					value={states.profile[key]}
					onChange={onChangeSelect(key)}
				>
					{Object.keys(list).sort().map((value:string,index:number)=>{
						const key = "menu" + index;
						return (
							<MenuItem key={key} value={value}>{list[value]}</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		)
	}
	const onChangeSelect = (name:keyof Profile) => (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as string;
		setStates({
			...states,
			profile:{...states.profile,[name]:value},
			updated:true,
		});
	};    


	const onChangeText = (name:keyof Profile) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			profile:{...states.profile,[name]:event.target.value},
			updated:true,
		});
	};
	//const onChange = (name:keyof Profile) => (event: React.ChangeEvent<HTMLInputElement>) => {
	//	if (!event) {
	//		return;
	//	}
	//	let value:string = event.target.value;
	//	setStates({
	//		...states,
	//		profile:{...states.profile,[name]:value},
	//		updated:true,
	//	});
	//};
	const generate = async ()=>{
		const res = await Ajax.createPhrase();
		if( res.ok ){
			setStates({
				...states,
				profile:{...states.profile,Phrase:res.phrase},
				updated:true,
			});
		}
	}
	const validate = async ()=>{
		if(!userState.user){
			return;
		}

		let error = Inc.defaultProfileError();
		let isError = false;
		let profile = states.profile;

		const Name = states.profile.Name.trim();
		if(Name===""){
			error["Name"] = "アーティスト名を入力してください";
			isError = true;
		} else if(Name.length>Inc.profileLength["Name"]){
			error["Name"] = Inc.profileLength["Name"] + "文字以内にしてください";
			isError = true;
		}

		const Phrase = states.profile.Phrase.trim();
		if( Phrase.length>Inc.profileLength["Phrase"] ){
			error["Phrase"] = "キャッチフレーズは"+Inc.profileLength["Phrase"]+"文字以内にしてください";
			isError = true;
		}

		const Story = states.profile.Story.trim();
		if( Story.length>Inc.profileLength["Story"] ){
			error["Story"] = "プロフィールは"+Inc.profileLength["Story"]+"文字以内にしてください";
			isError = true;
		}

		const Url = states.profile.Url.trim();
		if( Url!=="" && !Tools.validURL(Url) ){
			error["Url"] = "URLが正しくないようです。";
			isError = true;
		} else if( Url.length>Inc.profileLength["Url"] ){
			error["Url"] = "公式サイトURLは"+Inc.profileLength["Url"]+"文字以内にしてください";
			isError = true;
		}

		const TwitterId = states.profile.TwitterId.trim();
		if( TwitterId!=="" && !Tools.validTwitterId(TwitterId) ){
			error["TwitterId"] = "ツイッターIDが正しくないようです。";
			isError = true;
		}

		if( !isError ){
			setStates({...states,isLoading:true});
			//console.log("photo:"+states.photo);
			const res = await Ajax.updateProfile(states.profile,states.photo);
			if( res.ok ){
				//props.close();
				setStates({...states,isLoading:false});
				props.update({...states.profile,ImageKey:res.ImageKey,ImageWidth:res.ImageWidth,ImageHeight:res.ImageHeight});
				//setStates({...states,toast:'更新しました',error:error,isLoading:false,updated:false,defaultViewUrl:process.env.REACT_APP_VIEW_URL+states.profile.Code});
			} else {
				//setToast("登録できませんでした");
				setStates({...states,toast:'更新できませんでした',error:error,isLoading:false});
			}
		} else {
			setStates({...states,profile:profile,error:error});			
		}
	}
	const closeToast = ()=>{
		setStates({...states,toast:''});
	}
	const setPhoto = (photo:string)=>{
		setStates({...states,photo:photo,updated:true});
	}
	const close = ()=>{
		if( userState.profile && userState.profile.Name!=="" ){

		} else {
			userState.signOut();
		}
		props.close();
	}

	useEffect(()=>{
		if( props.open && userState.user ){
			//load(userState.user.uid);
			setStates({...states,profile:props.profile,isLoading:false,toast:"",updated:false,error:Inc.defaultProfileError(),defaultViewUrl:process.env.REACT_APP_VIEW_URL+props.profile.Code,photo:""});
		}
	},[props.open]);

	let updateButtonColor:"default"|"secondary" = "default";
	if( states.updated ){
		updateButtonColor = "secondary";
	}

	let imageURL = Inc.getProfileImageURL(userState.profile);
	
    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={props.close}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth="sm"
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
        >
			<DialogTitle>設定</DialogTitle>
			<DialogContent style={{paddingBottom:"3.5em"}}>
				<Grid container spacing={1}>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						<div style={{border:"1px solid silver"}}>
							<div style={{padding:"1em",textAlign:"center"}}>
								<PhotoUpload name="photos" photo={states.photo} source={imageURL} setPhoto={setPhoto}/>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						{putTextField("アーティスト名（必須）","Name")}
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						{putTextField("公式サイトURL","Url")}
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						{putTextField("キャッチフレーズ","Phrase",{endAdornment:(<Tooltip title="ランダム生成"><IconButton size="small" onClick={generate}><AssistantIcon color="primary"/></IconButton></Tooltip>)})}
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						{putTextField("プロフィール","Story",{rows:7})}
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						{putTextField("ツイッターID","TwitterId",{startAdornment:'@'})}
					</Grid>
					<Grid item xs={12}>
						{putSelect("表示スタイル","Style",Inc.viewStyles)}
					</Grid>
				</Grid>

			</DialogContent>
			<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
				<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={close}><CancelIcon/></IconButton></Tooltip>
			</DialogActions>
			<DialogActions>
				<Button variant="contained" color={updateButtonColor} onClick={validate} disabled={!states.updated}>更新</Button>
			</DialogActions>
			<Toast mes={states.toast} close={closeToast}/>
			<Loading isLoading={states.isLoading}/>
		</Dialog>
    )
}

export default Settings;
