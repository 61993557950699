export const CookieHashName = "rendez";
export const SiteName = "ランデブウ";
export const getCookieOptions = (domain:string | undefined)=>{
	//console.log("domain",domain);
	const expire = new Date();
	expire.setDate(expire.getDate() + 20);
	const options = {
		path: "/",
		expires: expire,
		maxAge: 86400*20,
		domain: domain,
		secure: false,
		httpOnly: false,
		sameSite: true,
	}
	return options;
};

export interface Dataset {
	id: string,
	label: string,	
}

export interface Profile {
	UserId: string,
	Code: string,
	Name: string,
	Url: string,
	TwitterId: string,
	Phrase: string,
	Story: string,
	Status: string,
	Style: string,
	ImageKey: string,
	ImageWidth: number,
	ImageHeight: number,
}
export interface ProfileError {
	UserId: string,
	Code: string,
	Name: string,
	Url: string,
	TwitterId: string,
	Phrase: string,
	Story: string,
	Status: string,
	Style: string,
	ImageKey: string,
	ImageWidth: string,
	ImageHeight: string,
}
export interface Stage {
	Id: number,
	UserId: string,
	Title: string,
	Place: string,
	Year: string,
	Month: string,
	Day: string,
	YearTo: string,
	MonthTo: string,
	DayTo: string,
	Start: string,
	Open: string,
	Memo: string,
	Artists: string,
	Method1: boolean,
	Method2: boolean
	PlaceUrl:string,
	StreamUrl: string,
	Condition: string,
	IsSingleDay: boolean,
	Order: string,
}
export interface StageError {
	Id: string,
	UserId: string,
	Title: string,
	Place: string,
	Year: string,
	Month: string,
	Day: string,
	YearTo: string,
	MonthTo: string,
	DayTo: string,
	Start: string,
	Open: string,
	Memo: string,
	Artists: string,
	Method: string,
	Method1: string,
	Method2: string,
	PlaceUrl:string,
	StreamUrl: string,
	Condition: string,
	IsSingleDay: string,
	Order: string,
}
export interface Stages {
	future: Stage[] | null,
	past: Stage[] | null,
	updated: Stage[] | null,
	isEnd: {
		future: boolean,
		past: boolean,
		updated: boolean,	
	},
	lastIndex: {
		future: number,
		past: number,
		updated: number,	
	},
}
export type StageListType = "future" | "past" | "updated";
export type HelpModeType = "" | "url" | "camera";
export const StageListItems = 20;

export class Inc {
	static readonly defaultProfile = ()=>{
		let profile:Profile = {
			UserId: "",
			Code: "",
			Name: "",
			Url: "",
			TwitterId: "",
			Phrase: "",
			Story: "",
			Status: "0",
			ImageKey: "",
			ImageWidth: 0,
			ImageHeight: 0,
			Style: "B",
		}
		return profile;
	}
	static readonly defaultProfileError = ()=>{
		let error:ProfileError = {
			UserId: "",
			Code: "",
			Name: "",
			Url: "",
			TwitterId: "",
			Phrase: "",
			Story: "",
			Status: "",
			ImageKey: "",
			ImageWidth: "",
			ImageHeight: "",
			Style: "",
		}
		return error;
	}
	static readonly defaultStages = ()=>{
		let stages:Stages = {
			future: null,
			past: null,
			updated: null,
			isEnd: {
				future: false,
				past: false,
				updated: false,
			},
			lastIndex: {
				future: 0,
				past: 0,
				updated: 0,
			},
		}
		return stages;
	}
	static readonly defaultStage = ()=>{
		const t = new Date();

		const year = t.getFullYear().toString();
		let month = (t.getMonth()+1).toString();
		if(month.length===1){
			month = "0"+month;
		}

		//console.log(t.getMonth().toString());
		let stage:Stage = {
			Id: 0,
			UserId: "",
			Title: "",
			Place: "",
			Year: year,
			Month: month,
			Day: "01",
			YearTo: year,
			MonthTo: month,
			DayTo: "01",
			Start: "",
			Open: "",
			Memo: "",
			Artists: "",
			Method1: true,
			Method2: true,
			PlaceUrl: "",
			StreamUrl: "",
			Condition: "1",
			IsSingleDay: true,
			Order: "",
		};
		return stage;
	}
	static readonly defaultStageError = ()=>{
		let error:StageError = {
			Id: "",
			UserId: "",
			Title: "",
			Place: "",
			Year: "",
			Month: "",
			Day: "",
			YearTo: "",
			MonthTo: "",
			DayTo: "",
			Start: "",
			Open: "",
			Memo: "",
			Artists: "",
			Method: "",
			Method1: "",
			Method2: "",
			PlaceUrl: "",
			StreamUrl: "",
			Condition: "",
			IsSingleDay: "",
			Order: "",
		};
		return error;
	}
	static readonly stageLength:{[key:string]:number;} = {
		"Title": 100,
		"Place": 50,
		"Memo": 200,
		"Artists": 400,
		"PlaceUrl": 400,
		"StreamUrl": 400,
	};
	static readonly profileLength:{[key:string]:number;} = {
		"Name": 30,
		"Code": 20,
		"Url": 400,
		"TwitterId": 15,
		"Phrase": 30,
		"Story": 1200,
	};
	static readonly conditions:{[key:string]:string;} = {
		"1":"開催",
		"2":"延期",
		"3":"中止",
		"9":"秘密",
	};
	static readonly months:{[key:string]:string;} = {
		"01":"1月",
		"02":"2月",
		"03":"3月",
		"04":"4月",
		"05":"5月",
		"06":"6月",
		"07":"7月",
		"08":"8月",
		"09":"9月",
		"10":"10月",
		"11":"11月",
		"12":"12月",
		"21":"上半期",
		"22":"下半期",
		"31":"春",
		"32":"夏",
		"33":"秋",
		"34":"冬",
		"99":"未定",
	};
	static readonly days:{[key:string]:string;} = {
		"01":"1日",
		"02":"2日",
		"03":"3日",
		"04":"4日",
		"05":"5日",
		"06":"6日",
		"07":"7日",
		"08":"8日",
		"09":"9日",
		"10":"10日",
		"11":"11日",
		"12":"12日",
		"13":"13日",
		"14":"14日",
		"15":"15日",
		"16":"16日",
		"17":"17日",
		"18":"18日",
		"19":"19日",
		"20":"20日",
		"21":"21日",
		"22":"22日",
		"23":"23日",
		"24":"24日",
		"25":"25日",
		"26":"26日",
		"27":"27日",
		"28":"28日",
		"29":"29日",
		"30":"30日",
		"31":"31日",
		"41":"上旬",
		"42":"中旬",
		"43":"下旬",
		"51":"前半",
		"52":"後半",
		"99":"未定",
	};
	static readonly holidays:{[key:string]:string} = {
		"20210101":"元日",
		"20210111":"成人の日",
		"20210211":"建国記念の日",
		"20210223":"天皇誕生日",
		"20210320":"春分の日",
		"20210429":"昭和の日",
		"20210503":"憲法記念日",
		"20210504":"みどりの日",
		"20210505":"こどもの日",
		"20210722":"海の日",
		"20210723":"スポーツの日",
		"20210808":"山の日",
		"20210809":"振替休日",
		"20210920":"敬老の日",
		"20210923":"秋分の日",
		"20211103":"文化の日",
		"20211123":"勤労感謝の日",
		"20220101":"元日",
		"20220110":"成人の日",
		"20220211":"建国記念の日",
		"20220223":"天皇誕生日",
		"20220321":"春分の日",
		"20220429":"昭和の日",
		"20220503":"憲法記念日",
		"20220504":"みどりの日",
		"20220505":"こどもの日",
		"20220718":"海の日",
		"20220811":"山の日",
		"20220919":"敬老の日",
		"20220923":"秋分の日",
		"20221010":"スポーツの日",
		"20221103":"文化の日",
		"20221123":"勤労感謝の日",
		"20230101":"元日",
		"20230102":"振替休日",
		"20230109":"成人の日",
		"20230211":"建国記念の日",
		"20230223":"天皇誕生日",
		"20230321":"春分の日",
		"20230429":"昭和の日",
		"20230503":"憲法記念日",
		"20230504":"みどりの日",
		"20230505":"こどもの日",
		"20230717":"海の日",
		"20230811":"山の日",
		"20230918":"敬老の日",
		"20230923":"秋分の日",
		"20231009":"スポーツの日",
		"20231103":"文化の日",
		"20231123":"勤労感謝の日",
		"20240101":"元日",
		"20240108":"成人の日",
		"20240211":"建国記念の日",
		"20240212":"振替休日",
		"20240223":"天皇誕生日",
		"20240320":"春分の日",
		"20240429":"昭和の日",
		"20240503":"憲法記念日",
		"20240504":"みどりの日",
		"20240505":"こどもの日",
		"20240506":"振替休日",
		"20240715":"海の日",
		"20240811":"山の日",
		"20240812":"振替休日",
		"20240916":"敬老の日",
		"20240922":"秋分の日",
		"20240923":"振替休日",
		"20241014":"スポーツの日",
		"20241103":"文化の日",
		"20241104":"振替休日",
		"20241123":"勤労感謝の日",
		"20250101":"元日",
		"20250113":"成人の日",
		"20250211":"建国記念の日",
		"20250223":"天皇誕生日",
		"20250224":"振替休日",
		"20250320":"春分の日",
		"20250429":"昭和の日",
		"20250503":"憲法記念日",
		"20250504":"みどりの日",
		"20250505":"こどもの日",
		"20250506":"振替休日",
		"20250721":"海の日",
		"20250811":"山の日",
		"20250915":"敬老の日",
		"20250923":"秋分の日",
		"20251013":"スポーツの日",
		"20251103":"文化の日",
		"20251123":"勤労感謝の日",
		"20251124":"振替休日",
		"20260101":"元日",
		"20260112":"成人の日",
		"20260211":"建国記念の日",
		"20260223":"天皇誕生日",
		"20260320":"春分の日",
		"20260429":"昭和の日",
		"20260503":"憲法記念日",
		"20260504":"みどりの日",
		"20260505":"こどもの日",
		"20260506":"振替休日",
		"20260720":"海の日",
		"20260811":"山の日",
		"20260921":"敬老の日",
		"20260922":"国民の休日",
		"20260923":"秋分の日",
		"20261012":"スポーツの日",
		"20261103":"文化の日",
		"20261123":"勤労感謝の日",		
	};
	static readonly viewStyles:{[key:string]:string;} = {
		"B":"バンドスタイル（会場名重視）",
		"T":"劇団スタイル（タイトル重視）",
	};
	static readonly viewModes:{[key:string]:string;} = {
		"future":"今後の予定",
		"past":"終了",
		"updated":"更新順",
		"story":"プロフィール",
	};
	static readonly getProfileImageURL = (profile:Profile)=>{
		if( profile && profile.ImageKey!=="" ){
			return process.env.REACT_APP_VIEW_URL+"img/"+profile.ImageKey+".jpg";
		}
		return "";
	}
}
