import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

interface AboutProps {
    open:boolean,
	close():void,
}

const About:React.FC<AboutProps> = (props)=> {
    //const classes = useStyles();
    if( !props.open ){
        return (null);
    }

	return (
  		<Dialog
	        open={props.open}
    	    onClose={props.close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
            <DialogTitle id="alert-dialog-title" className="dialogTitle"><InfoIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>有限会社レトロインクについて</DialogTitle>
			<DialogContent>
				<DialogContentText className="about">
					<p>このサービスは有限会社レトロインクが作成し、実験的に運営しています。</p>
					<p>有限会社レトロインクはウェブアプリケーションの開発会社です。社員は一人しかいません。このサービスの他、委託販売に特化したオンラインストア（架空ストア）や、保険用のレセプト発行システムや、販売会社用の業務日報ツール、営業管理ツールなどを開発しています。</p>
					<p style={{textAlign:"center"}}><a href="https://www.retro-ink.com/" target="_blank" rel="noopener noreferrer"><Button variant="contained" color="primary">会社サイトはこちら</Button></a></p>
					<table>
						<tr>
							<th>商号</th>
							<td>有限会社レトロインク</td>
						</tr>
						<tr>
							<th>所在地</th>
							<td>
							〒181-0001 東京都三鷹市井の頭4-26-7<br/>
							TEL: 0422-49-2903<br/>
							</td>
						</tr>
						<tr>
							<th>設立年月日</th>
							<td>2004年10月1日</td>
						</tr>
						<tr>
							<th>資本金</th>
							<td>300万円</td>
						</tr>
						<tr>
							<th>役員</th>
							<td>代表取締役　石村寛之</td>
						</tr>
					</table>					
				</DialogContentText>
				<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
					<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
				</DialogActions>
			</DialogContent>
		</Dialog>
    );
}

export default About;