import React from 'react';
//import useStyles from './Styles';
import CircularProgress from '@material-ui/core/CircularProgress';
//import loading from './loading.svg';
import "./Loading.scss";

interface LoadingProps {
	isLoading:boolean,
}

const Loading:React.FC<LoadingProps> = (props)=> {
	if(!props.isLoading){
		return (null);
	}
	//const classes = useStyles();
	return (
		<div id="loading" className="loading">
			<span className="spinner">
				<CircularProgress color="secondary"/>
			</span>
		</div>
    );
}

export default Loading;
