import React,{useState, useEffect} from 'react';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
//import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import Autosuggest from "react-autosuggest";
//import match from "autosuggest-highlight/match";
//import parse from "autosuggest-highlight/parse";
import Ajax from './Ajax';
import Toast from './Toast';
import DeleteStage from './DeleteStage';
import {Inc,Stage} from './Inc';
import Tools from './Tools';
import {UserContainer} from './UserContainer';


interface StageInputProps {
	open:boolean,
	stageId: number,
	close(updated:boolean):void,
}

const StageInput:React.FC<StageInputProps> = (props)=> {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userState = UserContainer.useContainer();

	const [states, setStates] = useState({
		stage:Inc.defaultStage(),
		error:Inc.defaultStageError(),
		disabledMonth:false,
		disabledDay:false,
		lastDay:31,
		disabledMonthTo:false,
		disabledDayTo:false,
		lastDayTo:31,
		isDeleteStage: false,
	});
	const [toast,setToast] = useState('');
	
	const onChangeText = (name:keyof Stage) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			stage:{...states.stage,[name]:event.target.value}
		});
	};

	const onChangeBoolean = (name:keyof Stage) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value:boolean = false;
		if( event.target.value==="true" ){
			value = true;
		}
		setStates({
			...states,
			stage:{...states.stage,[name]:value}
		});
	};

	const onChangeMethod = (index:number)=> (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value1:boolean = states.stage.Method1;
		let value2:boolean = states.stage.Method2;
		if( index===1 ){
			value1 = !value1;
		} else {
			value2 = !value2;
		}

		setStates({
			...states,
			stage:{...states.stage,"Method1":value1,"Method2":value2}
		});
	};

	const onChangeSelect = (name:keyof Stage) => (event: React.ChangeEvent<{ value: unknown }>) => {
		if( name==="Year" || name==="Month" || name==="Day" || name==="YearTo" || name==="MonthTo" || name==="DayTo" ){
			onChangeDate(name,event.target.value as string);
		} else {
			const value = event.target.value as string;
			setStates({
				...states,
				stage:{...states.stage,[name]:value},
			});
		}
    };    

    const onChangeDate = (name:keyof Stage,value:string) => {
		let stage = states.stage;
		updateDate( stage,name,value );
	}

	const updateDate = (stage:Stage,name:keyof Stage|"",value:string) => {
		let disabledMonth = states.disabledMonth;
		let disabledDay = states.disabledDay;
		let lastDay = states.lastDay;

		let disabledMonthTo = states.disabledMonthTo;
		let disabledDayTo = states.disabledDayTo;
		let lastDayTo = states.lastDayTo;

		if( value!=="" ){
			if( name==="Year" ){
				stage.Year = value;
			}
			if( name==="Month" ){
				stage.Month = value;
			}
			if( name==="Day" ){
				stage.Day = value;
			}
			if( name==="YearTo" ){
				stage.YearTo = value;
			}
			if( name==="MonthTo" ){
				stage.MonthTo = value;
			}
			if( name==="DayTo" ){
				stage.DayTo = value;
			}
		}

		try {
			const Year = parseInt(stage.Year);
			const Month = parseInt(stage.Month);
			lastDay = getLastDay( Year,Month );
		} catch(e) {
			console.log(e);
		}

		try {
			const Year = parseInt(stage.YearTo);
			const Month = parseInt(stage.MonthTo);
			lastDayTo = getLastDay( Year,Month );
		} catch(e) {
			console.log(e);
		}


		if( name==="Year" || name==="Month" ) {
			const Day = parseInt(stage.Day);
			if( Day>lastDay && Day<=31 ){
				stage.Day = lastDay.toString();
			}
		}
		if( name==="YearTo" || name==="MonthTo" ) {
			const Day = parseInt(stage.DayTo);
			if( Day>lastDayTo && Day<=31 ){
				stage.DayTo = lastDayTo.toString();
			}
		}

		////
		if( stage.Year==="9999" ){
			disabledMonth = true;
			disabledDay = true;
		}　else {
			disabledMonth = false;
			//console.log("month",stage.Month);
			if( parseInt(stage.Month)>12 ){
				disabledDay = true;
			}　else {
				disabledDay = false;
			}	
		}

		if( stage.YearTo==="9999" ){
			disabledMonthTo = true;
			disabledDayTo = true;
		}　else {
			disabledMonthTo = false;
			if( parseInt(stage.MonthTo)>12 ){
				disabledDayTo = true;
			}　else {
				disabledDayTo = false;
			}
		}

		if( name!=="" ){
			setStates({
				...states,
				stage:stage,
				disabledMonth:disabledMonth,
				disabledDay:disabledDay,
				lastDay:lastDay,
				disabledMonthTo:disabledMonthTo,
				disabledDayTo:disabledDayTo,
				lastDayTo:lastDayTo,
			});
		} else {
			setStates({
				...states,
				stage:stage,
				disabledMonth:disabledMonth,
				disabledDay:disabledDay,
				lastDay:lastDay,
				disabledMonthTo:disabledMonthTo,
				disabledDayTo:disabledDayTo,
				lastDayTo:lastDayTo,
				isDeleteStage:false,
				error:Inc.defaultStageError(),
			});
		}
    };    

	const getLastDay = ( Year:number,Month:number )=>{
		if( Month===12 ){
			Year++;
			Month=0;
		}
		//console.log(Year,Month,dt.getDate());
		return new Date(Year, Month, 0).getDate();
	}

	const putTextField = (label:string,key:keyof Stage,opt?:{[key:string]:any;})=>{
		let color:"primary"|"secondary"|undefined = "primary";
		if( opt && opt["color"] && (opt["color"]==="primary" || opt["color"]==="secondary") ){
			color = opt["color"];
		}
		let rows:number = 1;
		if( opt && opt["rows"] ){
			rows = opt["rows"];
		}
		let type:"text"|"time" = "text";
		if( opt && opt["type"] ){
			type = opt["type"];
		}

		let inputLabelProps:any = {};
		if( opt && opt["shrink"]===true ){
			inputLabelProps["shrink"] = true;
		}
		return (
			<TextField
				label={label}
				variant="outlined"
				fullWidth={true}
				onChange = {onChangeText(key)}
				value={states.stage[key]}
				error={states.error[key]!==""}
				helperText={states.error[key]}
				color={color}
				rows={rows}
				multiline={rows>1}
				InputLabelProps = {inputLabelProps}
				type={type}
			/>
		)		
	}

	const putSelect = (label:string,key:keyof Stage,list:{[key:string]:string;})=>{
		let disabledSelect = false;
		if( key==="Month" ){
			disabledSelect = states.disabledMonth;
		}
		if( key==="MonthTo" ){
			disabledSelect = states.disabledMonthTo;
		}
		if( key==="Day" ){
			disabledSelect = states.disabledDay;
		}
		if( key==="DayTo" ){
			disabledSelect = states.disabledDayTo;
		}
		let lastDay = 0;
		if( key==="Day" ){
			lastDay = states.lastDay;
		}
		if( key==="DayTo" ){
			lastDay = states.lastDayTo;
		}

		const labelId = key + "-label";
		const id = key + "-select";
		return (
			<FormControl variant="outlined" fullWidth={true}>
				<InputLabel shrink>{label}</InputLabel>
				<Select
					label = {label}
					labelId={labelId}
					id={id}
					value={states.stage[key]}
					onChange={onChangeSelect(key)}
					disabled={disabledSelect}
				>
					{Object.keys(list).sort().map((value:string,index:number)=>{
						const key = "menu" + index;
						let disabledMenu = false;
						if( lastDay>0 ){
							if( index<31 && lastDay<=index ){
								disabledMenu = true;
							}
						}
						return (
							<MenuItem key={key} value={value} disabled={disabledMenu}>{list[value]}</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		)
	}

	const putCheckbox = (onChange:any,label:string,checked:boolean,opt?:{[key:string]:any;})=>{
		let color:"primary"|"secondary"|undefined = "primary";
		if( opt && opt["color"] && (opt["color"]==="primary" || opt["color"]==="secondary") ){
			color = opt["color"];
		}
		return (
			<FormControlLabel
				control={
					<Checkbox
						onChange={onChange}
						color={color}
					/>
				}
				checked={checked}
				label={label}
			/>
		)
	}

	const validate = async ()=>{
		if(!userState.user){
			return;
		}
		let error = Inc.defaultStageError();
		let isError = false;

		const Title = states.stage.Title.trim();
		if(Title===""){
			error["Title"] = "タイトルを入力してください";
			isError = true;
		} else if(states.stage.Title.length>Inc.stageLength["Title"]){
			error["Title"] = Inc.stageLength["Title"] + "文字以内にしてください";
			isError = true;
		}

		const Place = states.stage.Place.trim();
		if(Place.length>Inc.stageLength["Place"]){
			error["Place"] = Inc.stageLength["Place"] + "文字以内にしてください";
			isError = true;
		}
		
		const PlaceUrl = states.stage.PlaceUrl.trim();
		if( PlaceUrl!=="" ){
			if( !Tools.validURL(PlaceUrl) ){
				error["PlaceUrl"] = "詳細ページURLが正しくないようです";
				isError = true;
			} else if(PlaceUrl.length>Inc.stageLength["PlaceUrl"]){
				error["PlaceUrl"] = Inc.stageLength["PlaceUrl"] + "文字以内にしてください";
				isError = true;
			}
		}

		const StreamUrl = states.stage.StreamUrl.trim();
		if( StreamUrl!=="" ){
			if( !Tools.validURL(StreamUrl) ){
				error["StreamUrl"] = "配信URLが正しくないようです";
				isError = true;
			} else if(StreamUrl.length>Inc.stageLength["StreamUrl"]){
				error["StreamUrl"] = Inc.stageLength["StreamUrl"] + "文字以内にしてください";
				isError = true;
			}
		}

		const Artists = states.stage.Artists.trim();
		if(Artists.length>Inc.stageLength["Artists"]){
			error["Artists"] = Inc.stageLength["Artists"] + "文字以内にしてください";
			isError = true;
		}

		const Memo = states.stage.Memo.trim();
		if(Memo.length>Inc.stageLength["Memo"]){
			error["Memo"] = Inc.stageLength["Memo"] + "文字以内にしてください";
			isError = true;
		}

		//if(states.stage.Method1===false && states.stage.Method2===false){
		//	error["Method"] = "どちらか一つは選んでください";
		//	isError = true;
		//}

		setStates({...states,error:error});
		if( !isError ){
			const order = makeStageOrder(states.stage);
			if( states.stage.Id===0 ){
				const res = await Ajax.addStage({...states.stage,Order:order,UserId:userState.user.uid});
				if( res.ok ){
					props.close(true);
				} else {
					setToast("登録できませんでした");
				}
			} else {
				const res = await Ajax.updateStage({...states.stage,Order:order});
				if( res.ok ){
					props.close(true);
				} else {
					setToast("登録できませんでした");
				}
			}
		}
	}

	const makeStageOrder = (stage:Stage)=>{
		let Order = stage.Year + stage.Month + stage.Day;
		if( stage.IsSingleDay && stage.Start!=="" ){
			Order = Order + stage.Start.replace(":","");
		} else {
			Order = Order + "0000";
		}
		return Order;
	}

	const load = async (stageId:number) => {
		if(!userState.user){
			return;
		}
		const res = await Ajax.getStage(stageId,userState.user.uid);
		//console.log(res);
		if( res.ok ){
			updateDate(res.stage,"","");
			//setStates({...states,isDeleteStage:false,stage:res.stage,error:Inc.defaultStageError()});
		} else {
			setToast("データの読込に失敗しました");
		}
	}

	const close = ()=>{
		props.close(false);
	}

	const closeToast = ()=>{
		setToast("");
	}

	const openDeleteStage = ()=>{
		setStates({...states,isDeleteStage:true});
	}
	const closeDeleteStage = (updated:boolean)=>{
		if( updated ){
			props.close(updated);
		} else {
			setStates({...states,isDeleteStage:false});
		}
	}

	let Years:{[key:string]:string;} = {};
	let Year = new Date().getFullYear() + 2;
	for( let y=2020; y<=Year; y++ ){
		Years[y.toString()] = y.toString()+"年";
	}
	Years["9999"] = "未定";

	useEffect(()=>{
		if( props.open ){
			if( props.stageId>0 ){
				load(props.stageId);
			} else {
				setStates({...states,
					stage:Inc.defaultStage(),
					error:Inc.defaultStageError(),
					disabledMonth:false,
					disabledDay:false,
					lastDay:31,
					disabledMonthTo:false,
					disabledDayTo:false,
					lastDayTo:31,		
					isDeleteStage:false,
				});
			}
		}
	},[props.open]);

	let title = "イベント作成";
	if( props.stageId>0 ){
		title = "イベント修正";
	}

	return (
        <Dialog
            aria-labelledby="simple-modal-Title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={props.close}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth="sm"
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
        >
			<DialogTitle>{title}</DialogTitle>
			<DialogContent style={{paddingBottom:"2em"}}>
				<DialogContentText>
					タイトル以外は省略できます。
				</DialogContentText>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={9}>
						{putTextField("タイトル","Title")}
					</Grid>
					<Grid item xs={12} sm={3}>
						{putSelect("状況","Condition",Inc.conditions)}
					</Grid>

					<Grid item xs={12}>
						{putTextField("会場名","Place",{color:"primary"})}
					</Grid>

					<Grid item xs={12}>
						{putTextField("詳細ページURL（公式サイトなど）","PlaceUrl",{color:"primary"})}
					</Grid>

					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Radio
									onChange={onChangeBoolean("IsSingleDay")}
									value="true"
									name="radio-is-single-Day"
									color="primary"
								/>
							}
							
							checked={states.stage.IsSingleDay===true}
							label="単日"
						/>
						<FormControlLabel
							control={
								<Radio
									onChange={onChangeBoolean("IsSingleDay")}
									value="false"
									name="radio-is-single-Day"
									color="secondary"
								/>
							}
							checked={states.stage.IsSingleDay===false}
							label="期間（初日と最終日）"
						/>
					</Grid>

					<Grid item xs={4} style={{marginBottom:"0.3em"}}>
						{putSelect("年","Year",Years)}
					</Grid>
					<Grid item xs={4} style={{marginBottom:"0.3em"}}>
						{putSelect("月","Month",Inc.months)}
					</Grid>						
					<Grid item xs={4} style={{marginBottom:"0.3em"}}>
						{putSelect("日","Day",Inc.days)}
					</Grid>

					{states.stage.IsSingleDay ? (
					<React.Fragment>
						<Grid item xs={6} sm={4} md={3} style={{marginBottom:"0.3em"}}>
							{putTextField("OPEN","Open",{type:"time",shrink:true})}
						</Grid>
						<Grid item xs={6} sm={4} md={3} style={{marginBottom:"0.3em"}}>
							{putTextField("START","Start",{type:"time",shrink:true})}
						</Grid>
					</React.Fragment>
					):(
					<React.Fragment>
						<Grid item xs={4} style={{marginBottom:"0.3em"}}>
							{putSelect("年","YearTo",Years)}
						</Grid>
						<Grid item xs={4} style={{marginBottom:"0.3em"}}>
							{putSelect("月","MonthTo",Inc.months)}
						</Grid>						
						<Grid item xs={4} style={{marginBottom:"0.3em"}}>
							{putSelect("日","DayTo",Inc.days)}
						</Grid>
					</React.Fragment>
					)}


					<Grid item xs={12}>
						{putCheckbox(onChangeMethod(1),"来場",states.stage.Method1,{"color":"primary"})}
						{putCheckbox(onChangeMethod(2),"配信",states.stage.Method2,{"color":"secondary"})}
						<FormControl error={true} style={{paddingTop:"0.5em"}}>
							<FormHelperText>{states.error.Method}</FormHelperText>
						</FormControl>
					</Grid>

					{states.stage.Method2 &&
					<Grid item xs={12}>
						{putTextField("配信URL","StreamUrl",{color:"secondary"})}
					</Grid>
					}
					<Grid item xs={12}>
						{putTextField("出演者","Artists",{rows:2})}
					</Grid>
					<Grid item xs={12}>
						{putTextField("補足（時間・場所・料金）","Memo",{rows:3})}
					</Grid>

				</Grid>
			</DialogContent>
			<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
				<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={close}><CancelIcon/></IconButton></Tooltip>
			</DialogActions>
			<DialogActions>
				{states.stage.Id>0 &&
				<Tooltip title="削除"><IconButton size="small" color="secondary" onClick={openDeleteStage} style={{position:"absolute",left:"1.5em",bottom:"1.0em"}}><DeleteIcon/></IconButton></Tooltip>
				}
				<Button variant="contained" size="large" color="primary" disabled={states.stage.Title===""} onClick={validate}>登録</Button>
			</DialogActions>
			<DeleteStage open={states.isDeleteStage} close={closeDeleteStage} stage={states.stage}/>
			<Toast mes={toast} close={closeToast}/>
		</Dialog>
    )
}

export default StageInput;
