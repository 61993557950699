import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Loading from './Loading';
import {Stage} from './Inc';
import {UserContainer} from './UserContainer';
import Ajax from './Ajax';
import Toast from './Toast';

interface Props {
	open:boolean,
	stage:Stage,
	close(updated:boolean):void,
}

const DeleteStage:React.FC<Props> = (props)=> {
    const [isLoading,setIsLoading] = useState(false);
	const [toast,setToast] = useState('');
    const userState = UserContainer.useContainer();
	const yes = async ()=>{
		if( !userState.user ){
			return;
		}
		setIsLoading(true);
		const res = await Ajax.removeStage(props.stage.Id,userState.user.uid);
		setIsLoading(false);
		if( res.ok ){
			props.close(true);
		} else {
			setToast('削除できませんでした');
		}	
	}
	const close = ()=>{
		props.close(false);
	}
	const closeToast = ()=>{
		setToast('');
	}
  
    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={close}
        >
            <Paper className="dialog dialog_login">
                <DialogTitle>このイベントを削除してよろしいですか？</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.stage.Title}<br/>
						{props.stage.Place}<br/>
						{props.stage.Year}/{props.stage.Month}/{props.stage.Day}<br/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="default" onClick={yes}>はい</Button>
					<Button variant="outlined" color="default" onClick={close}>いいえ</Button>
				</DialogActions>
            </Paper>
            <Loading isLoading={isLoading}/>
			<Toast mes={toast} close={closeToast}/>
        </Dialog>
    );
}

export default DeleteStage;
