import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BlockIcon from '@material-ui/icons/Block';
import DialogFooter from './DialogFooter';

interface NoResponseProps {
    isOpen:boolean,
}

const NoResponse:React.FC<NoResponseProps> = (props)=> {
    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{

    }

	return (
  		<Dialog
	        open={props.isOpen}
    	    onClose={close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
            <DialogTitle id="alert-dialog-title" className="dialogTitle"><BlockIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>サーバー応答なし</DialogTitle>
			<DialogContent>
				<DialogContentText>
					サーバーからの応答がありませんでした。<br/>
					回線の混雑もしくはサーバーの動作に異常が発生している可能性があります。<br/>
					しばらく待ってから再度お試しください。<br/>
					<br/>
                    また、<a href="https://twitter.com/liveguide_ri" className="link">ツイッター（ハイシン！と共通）</a>の情報もご確認ください。<br/>
				</DialogContentText>
				<DialogFooter/>
			</DialogContent>
		</Dialog>
    );
}

export default NoResponse;