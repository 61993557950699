import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

interface PolicyProps {
    open:boolean,
	close():void,
}

const Policy:React.FC<PolicyProps> = (props)=> {
    //const classes = useStyles();
    if( !props.open ){
        return (null);
    }

	return (
  		<Dialog
	        open={props.open}
    	    onClose={props.close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
            <DialogTitle id="alert-dialog-title" className="dialogTitle"><InfoIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>プライバシーポリシー</DialogTitle>
			<DialogContent>
				<DialogContentText className="about">
					<p>有限会社レトロインクは、お客様からお預かりした個人情報の重要性を認識し、その保護の徹底をはかり、お客様の信頼を得るために、個人情報に関する関係法令および規範を遵守するとともに、個人情報保護方針を定め確実な履行に努めることを宣言いたします。</p>
					<dl className="documentHelp">
						<dt>個人情報保護方針の適用範囲</dt>
						<dd>
							<p>当社の個人情報保護方針は、当社の運営するウェブサービスをお客様が利用する際に適用されます。</p>
						</dd>
						<dt>個人情報の利用</dt>
						<dd>
							<p>当社が収集した個人情報は、対象となるウェブサービスの運営のためだけに利用されます。その管理は当社だけが行い、当社の関連会社に対しても、情報を供給することはいたしません。また、何らかの事情でお客さまの個人情報をホームページ以外で使用する状況が生じた場合でも、お客さまの承諾なしに、第三者に開示・提供することはありません。
				ただし、法令に基づき開示することが必要である場合および裁判所、警察等公的機関から開示を求められた場合に関してはこの限りではないものとします。</p>
						</dd>
						<dt>個人情報の収集</dt>
						<dd>
							<p>個人情報を収集する場合は、あらかじめその目的・利用内容をお知らせし、お客さまの意志で同意・登録をいただくものとします。</p>
						</dd>
						<dt>個人情報の暗号化</dt>
						<dd>
							<p>当社のウェブサービスにおける会員ＩＤとパスワードなど、ユーザー認証においてきわめて重要とされるデータについては、盗難防止のために暗号化して保存してあります。そのため、メールや電話等で会員ＩＤやパスワードのお問い合わせをいただいても、当社では確認できませんので、お客様の側で、メモをとって保存するなどの管理をお願いいたします。</p>
						</dd>
						<dt>cookieについて</dt>
						<dd>
							<p>クッキーは、当社のウェブサービスをご利用を便利にするためのものであり、お客さまのプライバシーを侵害するものではなく、またお客さまのコンピューターに悪影響を及ぼすことはありません。ブラウザーの設定により、クッキーの受け取りを拒否することも可能ですが、その場合はウェブサービスのご利用に一部支障を来すことがあります。ブラウザーの設定方法は各ソフト製造元へお問い合わせください。</p>
						</dd>
						<dt>セキュリティについて</dt>
						<dd>
							<p>個人情報のやり取りが発生する手続きにおいては、ＳＳＬと呼ばれる強固な暗号通信技術を採用しています。そのため、ご利用の最中に、セキュリティに関する警告が何度か表示される場合がございますが、ＳＳＬが有効に機能している証拠ですので、そのままご利用下さい。また、ＳＳＬ技術に対応したブラウザのご使用を推奨いたします。これらより下位のブラウザをご使用になると、不具合の発生する場合がございますので、あらかじめご了承ください</p>
						</dd>
						<dt>個人情報の取扱いについて</dt>
						<dd>
							<p>当社は、お客様の個人情報を管理する際は管理責任者をおき、適切な管理を行うとともに、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破損、改ざん、漏洩などを防止するため、セキュリティシステムの維持、社内管理体制、システムの整備、社員教育の徹底等必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。
				また、個人情報に係るデータベース等へのアクセスについてはアクセス権を有する者を限定し、社内においても不正な利用がおこらないように厳重に管理します。</p>
						</dd>
						<dt>お客様の責任について</dt>
						<dd>
							<p>当社のウェブサービスのご利用は、お客さまの責任において行われるものとし、日本国の法律の条例に拘束されることに同意するものとします。また当社は、当サイト上で、お客さまの環境において当サイトのウェブサービスが適切であるか否かについては、如何なる表明または保証も行いません。</p>
						</dd>
						<dt>この個人情報保護方針の改訂について</dt>
						<dd>
							<p>今後、当社は個人情報保護方針の全部または一部を改訂することがあります。重要な変更がある場合には、当社のホームページ上において、分かりやすい方法でお知らせいたします。</p>
						</dd>
					</dl>
					<p className="signature">2005.7.26<br />有限会社レトロインク</p>
				</DialogContentText>
				<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
					<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
				</DialogActions>
			</DialogContent>
		</Dialog>
    );
}

export default Policy;