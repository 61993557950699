import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

interface RuleProps {
    open:boolean,
	close():void,
}

const Rule:React.FC<RuleProps> = (props)=> {
    //const classes = useStyles();
    if( !props.open ){
        return (null);
    }

	return (
  		<Dialog
	        open={props.open}
    	    onClose={props.close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
            <DialogTitle id="alert-dialog-title" className="dialogTitle"><InfoIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>利用規約・免責事項</DialogTitle>
			<DialogContent>
				<DialogContentText className="about">

				<p>有限会社レトロインク（以下、当社）は、当社が運営するサービス「獏と九九」（以下、本サービス）の利用と提供に関し、以下の通り、獏と九九利用規約（以下、本規約）を定めます。</p>
	<dl className="documentHelp">
		<dt>1．定義</dt>
		<dd>
			<p>本規約において使用する用語の意義は、本規約において別段の定めがある場合を除き、次の各号に定める通りとします。</p>
			<ul>
				<li>本サービスとは、獏と九九の全サービスをいいます。</li>
				<li>利用者とは、本規約に基づき本サービスにログインをしてイベントを登録した方をいいます。</li>
				<li>イベントとは、利用者が出演もしくは関係する音楽、演劇、落語などの興行情報をいいます。</li>
				<li>閲覧者とは、利用者が公開したイベントを本サービス上で閲覧した第三者をいいます。</li>
			</ul>
		</dd>
		<dt>2. 本規約の適用</dt>
		<dd>
			<p>本規約は、本サービスの利用および運用に関して、当社と利用者の関係の全てに適用されます。</p>
			<p>利用者はイベントの登録によって本規約の内容を全て承諾したものとみなします。</p>
		</dd>
		<dt>3. 本規約の変更</dt>
		<dd>
			<p>当社は、本規約の全部又は一部を変更、中断、又は終了（以下、変更）する際には、利用者へ事前の通知を行うものとします。ただし、緊急を要すると当社が判断した場合には、事後の通知でそれに代えることができるものとします。</p>
			<p>また、変更後に本サービスのご利用があった時点で、変更後の本規約に同意したものとみなします。</p>
		</dd>
		<dt>4. 通知</dt>
		<dd>
			<p>当社が利用者に対して、通知、連絡等を行う必要があると判断した場合、電子メール、本サービスのWebサイトへの掲載など、当社が適当と判断する方法により行うものとします。</p>
			<p>前項における電子メールによる通知、連絡等は、利用者が本サービスに登録している電子メールアドレスに対して行うものとします。</p>
			<p>当社が通知、連絡等を本サービスのWebサイト上で行う場合は、当該通知、連絡等をWebサイトに掲載した日をもって、利用者に当該通知、連絡等が到達したものとみなします。また、電子メールによって通知、連絡等を行う場合には、当社が利用者に対して電子メールを発信した時点をもって、利用者に当該通知、連絡等が到達したものとみなします。</p>
		</dd>
		<dt>5. 本サービスの内容</dt>
		<dd>
			<p>本サービスは、出演情報の告知管理システムを利用者に提供するものです。</p>
			<p>当社は、利用者に事前に通知することなく本サービスの内容の全部又は一部の変更を行うことができるものとし、当社は変更により利用者に生じた損害、結果について一切責任を負いません。</p>
		</dd>
		<dt>6. 利用の開始</dt>
		<dd>
			<p>本サービスへのログインおよびイベントの登録は、利用者が自己の判断と責任により登録するものとし、その内容によって利用者に不利益が生じても当社は一切責任を負わないものとします。</p>
			<p>利用者は、本サービスを利用するにあたり当社に登録した情報に誤り又は変更が生じた場合は、ただちに本サービス上の利用者ページにおいて、情報の訂正、修正又は変更を行うものとします。</p>
			<p>また、利用者登録において、利用者が他社の提供するＳＮＳアカウントの利用を選択した場合、当社は該当ＳＮＳアカウントの情報を利用者登録および利用者登録の確認以外の目的では使用しないものとします。</p>
		</dd>
		<dt>7. 退会・解約</dt>
		<dd>
			<p>利用者は、当社が本サービス上で定める方法で、利用者が本サービスに登録した全情報をいつでも削除することができるものとします。</p>
			<p>利用者情報の削除は、退会・解約を意味するものとします。</p>
		</dd>
		<dt>8. 免責事項</dt>
		<dd>
			<p>利用者は、次の各号をあらかじめ承諾したものとみなします。</p>
			<ul>
				<li>本サービスの利用において利用者同士または利用者と閲覧者の間に生じたトラブルは、両者間の話し合いで解決するものとし、当社および本サービスは一切責任を負わないものとします。</li>
				<li>本サービスに利用において利用者に不利益が生じても当社は一切責任を負わないものとします。</li>
			</ul>
		</dd>

		<dt>9. 個人情報</dt>
		<dd>
			<p>当社が本サービスで取得した利用者の個人情報は、当社が別途定めるプライバシーポリシーに従って取り扱うものとします。</p>
		</dd>
		<dt>10. 本サービスの停止</dt>
		<dd>
			<p>当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく一時的に本サービスを中断することができるものとします。</p>
			<ul>
				<li>サーバ、通信回線若しくはその他の設備の故障、障害の発生又はその他の事由により本サービスの提供ができなくなった場合</li>
				<li>システム(サーバ、通信回線や電源、それらを収容する建築物などを含む)の保守、点検、修理、変更を定期的に又は緊急に行う場合</li>
				<li>火災、停電などにより本サービスの提供ができなくなった場合</li>
				<li>地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合</li>
				<li>戦争、変乱、暴動、騒乱、労働争議、疫病の蔓延等その他不可抗力により本サービスの提供ができなくなった場合</li>
				<li>法令による規制、司法命令等が適用された場合</li>
				<li>その他、運用上、技術上当社が本サービスの一時的な中断を必要と判断した場合</li>
			</ul>
		</dd>
		<dt>11. 利用環境の整備</dt>
		<dd>
			<p>利用者は、本サービスを利用するために必要な通信機器、ソフトウェア、通信回線などのすべてを自己の責任と費用をもって準備し、本サービスを利用するために必要となる全ての環境の整備および維持管理を行うものとします。</p>
			<p>当社は、利用者の使用する本サービスの利用に供する装置、ソフトウェア又は通信網の瑕疵、障害、動作不良、もしくは不具合により、利用者に損害が生じたときであっても一切の責任を負いません。</p>
		</dd>
		<dt>12. 認証の管理責任</dt>
		<dd>
			<p>利用者が本サービス上を利用する上での認証に必要な情報は利用者が責任をもって管理するものとします。利用者によるパスワード等の紛失、失念、漏洩、開示又はこれらを起因とする第三者のパスワード等の不正使用等から生じた損害については、当社は一切その責任を負わないものとします。</p>
		</dd>
		<dt>13. 利用者の禁止事項</dt>
		<dd>
			<p>利用者は、本サービスの利用に関して、以下の行為を行ってはならないものとします。</p>
			<ul>
				<li>本サービスを利用する権利の第三者への譲渡および転売行為</li>
				<li>当社もしくは第三者の著作権、商標権などの知的財産権その他の権利を侵害する行為、又は侵害するおそれのある行為</li>
				<li>法令もしくは公序良俗に違反し、又は当社もしくは第三者に不利益を与える行為</li>
				<li>他者を差別もしくは誹謗中傷し、又はその名誉もしくは信用を毀損する行為</li>
				<li>詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
				<li>第三者になりすまして本サービスを利用する行為</li>
				<li>ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為</li>
				<li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為</li>
				<li>その他、当社が不適当と判断する行為</li>
			</ul>
			<br/>
			<p>当社は、本サービスの利用に関して、利用者の行為が前項各号のいずれかに該当するものであると判断した場合、事前に利用者に通知することなく、本サービスの全部又は一部の提供を一時停止することができるものとします。</p>
		</dd>
		<dt>14. その他</dt>
		<dd>
			<p>本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
			<p>本規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるものとします。</p>
		</dd>
	</dl>

	<p>附則</p>
	<p>
		本規約は2021年6月20日から施行します。<br/>
		2021年6月20日 施行
	</p>

				</DialogContentText>
				<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
					<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
				</DialogActions>
			</DialogContent>
		</Dialog>
    );
}

export default Rule;