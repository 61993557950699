import React from 'react';
import logoImg from './img/logo.png';

const DialogFooter:React.FC = ()=> {
	return (
		<div className="dialogFooter">
			<div className="logo">
				<img src={logoImg} alt="獏と九九ロゴ"/><br/>
				獏と九九<br/>
			</div>
			<div className="copyright">
				retro-ink &copy;2021
			</div>
		</div>
    );
}

export default DialogFooter;
