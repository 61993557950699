import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import logoImg from './img/logo.png';
import {UserContainer} from './UserContainer';

interface FooterProps {
	move(target:string):void,
}

const Footer:React.FC<FooterProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const goInput = ()=>{
		props.move('input');
	}
	return (
		<React.Fragment>
			<div className="footer">
				<div className="logo">
					<a href="https://home.tapir99.info/">
						<img src={logoImg} alt="獏と九九のロゴ"/><br/>
						獏と九九<br/>
					</a>
				</div>
				<div className="copyright">
					retro-ink &copy;2021
				</div>
			</div>
			{userState.user &&
			<Hidden smUp>
				<Fab color="secondary" style={{position:"fixed",bottom:"1.5em",right:"1.5em"}}>
					<IconButton onClick={goInput}><AddBoxIcon fontSize="large" style={{color:"#FFFFFF"}}/></IconButton>
				</Fab>
			</Hidden>
			}
		</React.Fragment>
    );
}

export default Footer;
