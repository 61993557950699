import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
//import Login from './Login';
//import Title from './Title';
import plateImg from './img/plate.png';


interface WelcomeProps {
	move(target:string):void,
}

const Welcome:React.FC<WelcomeProps> = (props)=> {
	const openLogin = ()=>{
		//setStates({...states,isLogin:true});
		props.move("login");
	}
	const openSample = ()=>{
		//setStates({...states,isLogin:true});
		props.move("sample");
	}
	return (
		<div className="welcome">
			<div className="image">
				<img src={plateImg} alt="獏と九九" title="獏と九九"/>
			</div>
			<Hidden xsDown implementation="js">
			<div className="btn_wide">
				<p>
					獏と九九は出演予定の告知ページを簡単に作成できるサービスです。
				</p>
				<p>
					バンド、演劇、落語、展覧会など、ジャンル問わずにご利用いただけます。
				</p>
				<p>
					ご利用料金は無料です。
				</p>
				<div>
					<Button variant="contained" color="secondary" onClick={openLogin}>ログインして始める</Button>
				</div>
				<div>
					<Button variant="outlined" color="secondary" onClick={openSample} style={{marginTop:"0.5em"}}>サンプルを見る</Button>
				</div>
			</div>
			</Hidden>
			<Hidden smUp implementation="js">
			<div className="btn">
				<p>
					獏と九九は出演予定の告知ページを簡単に作成できるサービスです。
				</p>
				<p>
					バンド、演劇、落語、展覧会など、ジャンル問わずにご利用いただけます。
				</p>
				<p>
					ご利用料金は無料です。
				</p>
				<div>
					<Button variant="contained" color="secondary" onClick={openLogin}>ログインして始める</Button>
				</div>
				<div>
					<Button variant="outlined" color="secondary" onClick={openSample} style={{marginTop:"0.5em"}}>サンプルを見る</Button>
				</div>
			</div>
			</Hidden>
		</div>
	);
}

export default Welcome;
