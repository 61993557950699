import React,{useState,useEffect} from 'react';
//import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
//import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
//import FormControl from '@material-ui/core/FormControl';
import MailIcon from '@material-ui/icons/Mail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import PersonIcon from '@material-ui/icons/Person';
import HaishinImg from './img/haishin.png';
import R7Img from './img/r7.png';
import StoreImg from './img/store.png';
import YesNo from './YesNo';
import Ajax from './Ajax';
import {UserContainer} from './UserContainer';

interface DrawerMenuProps {
	open:boolean,
	move(target:string):void,
	toggle(open:boolean):void,
}

const DrawerMenu:React.FC<DrawerMenuProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const [isLeave,setIsLeave] = useState(false);
	const [isOpen,setIsOpen] = useState(false);

	const login = ()=>{
		props.toggle(false);
		props.move("login");
	}
	const logout = ()=>{
        userState.signOut();
		props.toggle(false);
    }
	const leave = ()=>{
		setIsLeave(true);
	}
	const leaveYes = async ()=>{
		if(!userState.user){
			return;
		}
		const res = await Ajax.removeUser(userState.user.uid);
		if( res.ok ){
	        userState.signOut();
			setIsLeave(false);
			props.toggle(false);
		} else {

		}
	}
	const leaveNo = ()=>{
		setIsLeave(false);
	}
	const onOpen = ()=>{
		//console.log("open");
		props.toggle(true);
		//setIsOpen(true);
	}
	const onClose = ()=>{
		//console.log("close");
		//setIsOpen(false);
		props.toggle(false);
	}
	const about = ()=>{
		props.move("about");
	}
	const rule = ()=>{
		props.move("rule");
	}
	const policy = ()=>{
		props.move("policy");
	}
	//const changeCode = ()=>{
	//	props.move('code');
	//	props.onClose();
	//}

	let account = userState.user?.displayName;
	let accountTitle = "アカウント";
	let provider = "";
	if( userState.ready ){
		if( userState.user ){
			if( account ){
				if( userState.user.providerData[0] ){
					if( userState.user.providerData[0].providerId==="twitter.com" ){
						provider = "twitter";
					}
					if( userState.user.providerData[0].providerId==="google.com" ){
						provider = "google";
					}
				}
				accountTitle = accountTitle + "｜" + provider;
			} else {
				provider = "メール";
				account = "メールでログイン";
			}
		}
	}
	if( provider==="" ){
		account = "ログインしてません";
	}

	let mail = userState.user?.email;
	if(!mail){
		mail = "なし";
	}

	useEffect( ()=>{
		//console.log("open",props.open);
		setIsOpen(props.open);
	},[props.open]);

	return (
		<SwipeableDrawer open={isOpen} onOpen={onOpen} onClose={onClose} className="drawer">
			<List
				subheader={
					<ListSubheader disableSticky={true}>アカウント情報</ListSubheader>
				}
			>
				<ListItem>
					<ListItemAvatar>
						{userState.user ? (
						<Avatar style={{backgroundColor:"#333333"}}>
							<VerifiedUserIcon/>
						</Avatar>
						):(
						<Avatar>
							<VerifiedUserIcon/>
						</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText primary={accountTitle} secondary={account}/>
				</ListItem>
				<ListItem>
					<ListItemAvatar>
						{mail!=="なし" ? (
						<Avatar style={{backgroundColor:"#333333"}}>
							<MailIcon/>
						</Avatar>
						):(
						<Avatar>
							<MailIcon/>
						</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText primary="メールアドレス" secondary={mail}/>
				</ListItem>
				{userState.user ? (
				<React.Fragment>
					<ListItem button onClick={logout}>
						<ListItemText primary="ログアウト"/>
					</ListItem>
					<ListItem style={{padding:"0"}}>
						<Button variant="text" onClick={leave}>退会</Button>
					</ListItem>
				</React.Fragment>
				):(
					<ListItem button onClick={login}>
						<ListItemText primary="ログイン"/>
					</ListItem>
				)}
			</List>			
			<Divider style={{marginTop:"2em"}}/>
			<List
				component="nav"
				subheader={
					<ListSubheader disableSticky={true}>規約・お問い合わせ</ListSubheader>
				}
			>
				<a href="https://twitter.com/liveguide_ri" target="_blank" rel="noopener noreferrer" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar style={{backgroundColor:"#333333"}}>
							<TwitterIcon style={{color:"#FFFFFF"}}/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="ハイシン！ twitter" secondary="お問い合わせはDMでどうぞ"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<ListItem button onClick={about}>
					<ListItemText primary="有限会社レトロインクについて"/>
				</ListItem>
				<ListItem button onClick={rule}>
					<ListItemText primary="利用規約・免責事項"/>
				</ListItem>
				<ListItem button onClick={policy}>
					<ListItemText primary="プライバシーポリシー"/>
				</ListItem>
			</List>
			<Divider style={{marginTop:"2em"}}/>
			<List
				component="nav"
				subheader={
					<ListSubheader disableSticky={true}>姉妹サイト</ListSubheader>
				}
			>
				<a href="https://liveguide.retro-ink.com/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={HaishinImg} alt="ハイシン！ロゴ"/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="ハイシン！" secondary="音楽・落語・演劇の生配信番組ガイド"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://tools.r7ticket.jp/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={R7Img} alt="R7 TICKET SERVICEロゴ"/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="R7 TICKET SERVICE" secondary="当日精算に特化したチケット予約サービス"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
				<a href="https://store.retro-biz.com/" rel="noopener noreferrer" target="_blank" className="link"><ListItem button>
					<ListItemAvatar>
						<Avatar>
							<img src={StoreImg} alt="架空ストアロゴ"/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="架空ストア" secondary="雑貨委託・本当に売ってる架空のお店"/>
					<ListItemSecondaryAction>
						<IconButton><ArrowForwardIcon/></IconButton>
					</ListItemSecondaryAction>

				</ListItem></a>
			</List>
			<Divider style={{marginTop:"2em",marginBottom:"2em"}}/>
			<YesNo onYes={leaveYes} onNo={leaveNo} title="退会" text="退会するとプロフィールやイベント情報などすべての登録内容が削除されます。一度削除すると元に戻すことはできませんのでご注意ください。退会してよろしいですか？" isOpen={isLeave}/>
		</SwipeableDrawer>
    );
}

export default DrawerMenu;
