import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "haishin-60b1a.firebaseapp.com",
    databaseURL: "https://haishin-60b1a.firebaseio.com",
    projectId: "haishin-60b1a",
    storageBucket: "haishin-60b1a.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
//console.log("firebase init");

firebase.initializeApp(config)
export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const twitterAuth = new firebase.auth.TwitterAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();
