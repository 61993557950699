export default class Tools {
	static readonly validURL = (myURL:string)=> {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
			'(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
			'(\\#[-a-z\\d_]*)?$','i');
		return pattern.test(myURL);
	}
	static readonly validTwitterId = (myId:string)=> {
		var pattern = new RegExp('^[0-9A-Za-z_]{4,15}$','i');
		return pattern.test(myId);
	}
	static readonly validCode = (myId:string)=> {
		var pattern = new RegExp('^[0-9A-Za-z_]{4,20}$','i');
		return pattern.test(myId);
	}
	static readonly toHan = (input:string)=>{
		return input.replace(/[！-～]/g,
			function(input){
				return String.fromCharCode(input.charCodeAt(0)-0xFEE0);
			}
		);
	};	
}