import React,{useState, useEffect} from 'react';
//import {Redirect} from 'react-router-dom';
import {useHistory} from 'react-router-dom'
import {UserContainer} from './UserContainer';
import Header from './Header';
import Footer from './Footer';
import Welcome from './Welcome';
import StageList from './StageList';
import StageInput from './StageInput';
import Settings from './Settings';
import CodeInput from './CodeInput';
import Loading from './Loading';
import Login from './Login';
import DrawerMenu from './DrawerMenu';
import Toast from './Toast';
import Ajax from './Ajax';
import About from './About';
import Rule from './Rule';
import Policy from './Policy';
import Sample from './Sample';
import {Profile} from './Inc';

interface MainProps {
    mode:string,
}

const Main:React.FC<MainProps> = (props)=> {
	//const [mode,setMode] = useState("list");
	const [states,setStates] = useState({
		mode:props.mode,
		count:0,
		editId: 0,
		isAvailable: true,
		isLoading: true,
		readyProfile: false,
		toast: "",
	});
	const [isDrawer,setIsDraswer] = useState(false);
    const userState = UserContainer.useContainer();
	const hisotry = useHistory();
	//const [isLoading,setIsLoading] = useState(true);

	const move = (target:string)=>{
		setStates({...states,mode:target,editId:0});
	}
	const edit = (id:number)=>{
		setStates({...states,mode:"input",editId:id});
	}
	const closeInput = (updated:boolean)=>{
		//setMode("list");
		let count = states.count;
		if( updated ){
			count++;
		}
		setStates({...states,mode:"list",count:count});
	}
	const closeDialog = ()=>{
		//setMode("list");
		if( props.mode!=="list" ){
			hisotry.push('/');
		}
		setStates({...states,mode:"list"});
	}
	const toggleDrawer = (open:boolean)=>{
		setIsDraswer(open);
	}
	const updateProfile = (profile:Profile) => {
		let toast = "";
		if( userState.profile.Name!=="" ){
			toast = "プロフィール設定を変更しました";
		}
		setStates({...states,toast:toast,mode:"list"});
		userState.setProfile(profile);
	}
	const updateCode = (code:string,status:string) => {
		userState.setProfile({...userState.profile,Code:code,Status:status});
		setStates({...states,toast:"公開設定を変更しました",mode:"list"});
	}
	const closeToast = ()=>{
		setStates({...states,toast:""});
	}
	const loadProfile = async ()=>{
		if(!userState.user){
			setStates({...states,isLoading:!userState.ready});
			return;
		}
		const res = await Ajax.getProfile(userState.user.uid);
		//console.log("loadProfile",res);
		if( res.ok ){
			userState.setProfile(res.profile);
			if( res.profile.Name!=="" ){
				setStates({...states,mode:props.mode,readyProfile:true,isLoading:!userState.ready});
			} else {
				setStates({...states,mode:"settings",readyProfile:true,isLoading:!userState.ready});
			}
		}
	}

	useEffect(()=>{
		loadProfile();
	},[userState.user,userState.ready]);

	let isStageList = false;
	let isWelcome = false;
	if( userState.user && userState.profile && userState.profile.Name!=="" ){
		isStageList = true;
		isWelcome = false;
	} else if( (userState.user && states.readyProfile && userState.profile.Name==="") || (!userState.user && userState.ready) ){
		isStageList = false;
		isWelcome = true;
	}

    return (
		<div className="main">
			<Header move={move} menu={toggleDrawer}/>
			{isStageList &&
        	<StageList edit={edit} move={move} count={states.count}/>
			}
			{isWelcome &&
        	<Welcome move={move}/>
			}
			<StageInput close={closeInput} open={states.mode==='input'} stageId={states.editId}/>
			<Settings close={closeDialog} open={states.mode==='settings'} profile={userState.profile} update={updateProfile}/>
			<CodeInput close={closeDialog} open={states.mode==='code'} code={userState.profile?.Code} status={userState.profile?.Status} update={updateCode}/>
			<Footer move={move}/>
			<DrawerMenu open={isDrawer} toggle={toggleDrawer} move={move}/>
			<Login close={closeDialog} open={states.mode==='login'}/>
			<About close={closeDialog} open={states.mode==='about'}/>
			<Rule close={closeDialog} open={states.mode==='rule'}/>
			<Policy close={closeDialog} open={states.mode==='policy'}/>
			<Sample close={closeDialog} open={states.mode==='sample'}/>
			<Loading isLoading={states.isLoading}/>
			<Toast mes={states.toast} close={closeToast}/>
		</div>
    )
}

export default Main;
