import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import DialogFooter from './DialogFooter';
//import logoImg from './img/logo.png';

interface OldVersionProps {
    latest:string,
    isOpen:boolean,
}

const OldVersion:React.FC<OldVersionProps> = (props)=> {
    if( !props.isOpen ){
        return (null);
	}
	
	const close = ()=>{

	}

	return (
		<Dialog
	        open={props.isOpen}
    	    onClose={close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
            <DialogTitle id="alert-dialog-title" className="dialogTitle"><SystemUpdateIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>最新バージョンにアップデートして下さい</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} style={{marginBottom:"1em"}}>
					<Grid item xs={12} sm={6}><div className="verBox">
						<h3>ご利用中のバージョン</h3>
						<div className="current">
							{process.env.REACT_APP_VERSION}
						</div>
					</div></Grid>
					<Grid item xs={12} sm={6}><div className="verBox">
						<h3>最新バージョン</h3>
						<div className="latest">
							{props.latest}
						</div>
					</div></Grid>
				</Grid>
				<div className="verGuide">
					<h3>スマホ（iOS/Android）の場合</h3>
					<div>
						「画面を上から下へスワイプして放す」を何度か行うことで更新されます。
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>アプリの場合</h3>
					<div>
						「ホーム画面に追加」してアプリとして起動している場合は、アプリを終了してからもう一度起動すると最新バージョンが読み込まれます。
						<p>
						※２～３回繰り返さないと更新されない場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>Windowsの場合</h3>
					<div>
						強制リロード（Ctrl+F5）で最新バージョンが読み込まれます。<br/>
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
				<div className="verGuide">
					<h3>Macの場合</h3>
					<div>
						強制リロード（command+R 又は command+Shift+R）で最新バージョンが読み込まれます。<br/>
						<p>
						※キャッシュの削除が必要になる場合もあります。<br/>
						</p>
					</div>
				</div>
				<DialogFooter/>
			</DialogContent>
        </Dialog>

    );
}

export default OldVersion;
