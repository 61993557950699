import {Stage,Profile} from './Inc';

export default class Ajax {
	static token:string = "";
	static url = process.env.REACT_APP_SYSTEM_URL;
	static setToken = (tk:string)=>{
		Ajax.token = tk;
	}
	static send = (path:string,data:any,func:any)=>{
		//console.log("send",Ajax.url+path,data);
		fetch(Ajax.url+path, {
			method : 'POST',
			body : JSON.stringify(data),
			credentials: "include",
		}).then(response => response.json())
		.then((json) => {
			func(json);
		})
		.catch(err => {
			console.log('rejected:', err); // rejected: TypeError: Failed to fetch
		});
	}

	static hello = async ()=>{
		try {
			return await fetch(Ajax.url+"hello",{
				method: 'POST',
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static newToken = async (userId:string)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/get",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static removeUser = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"user/remove",{
				method: 'POST',
				body: JSON.stringify({UserId:userId}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static addStage = async (stage:Stage)=>{
		try {
			return await fetch(Ajax.url+"stage/add",{
				method: 'POST',
				body: JSON.stringify({Stage:stage,Token:Ajax.token},function(key,value){
					if( key==="CreatedAt" || key==="UpdatedAt" ){
						return null;
					}
					return value;
				}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateStage = async (stage:Stage)=>{
		try {
			return await fetch(Ajax.url+"stage/update",{
				method: 'POST',
				body: JSON.stringify({Stage:stage,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static removeStage = async (stageId:number,userId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/remove",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getStage = async (stageId:number,userId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/get",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getStages = async (userId:string,listType:string,lastIndex:number)=>{
		try {
			return await fetch(Ajax.url+"stage/list",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,ListType:listType,LastIndex:lastIndex,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getProfile = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"profile/get",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateProfile = async (profile:Profile,image:string)=>{
		try {
			return await fetch(Ajax.url+"profile/update",{
				method: 'POST',
				body: JSON.stringify({Profile:profile,Image:image,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateCode = async (userId:string,code:string,status:string)=>{
		try {
			return await fetch(Ajax.url+"code/update",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Code:code,Status:status,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getDownloadImageKey = async (userId:string,size:string)=>{
		try {
			return await fetch(Ajax.url+"image/download",{
				method: 'POST',
				body:JSON.stringify({UserId:userId,Size:size,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static downloadImage = async (userId:string,size:string,funcOutputImage?:any)=>{
		try {
			return await fetch(Ajax.url+"image/download", {
				method : 'POST',
				body:JSON.stringify({UserId:userId,Size:size,Token:Ajax.token}),
				credentials: "include",
			}).then((response) => {
				//console.log("status",response.status);
				if( response.status!==200 ){
					const error = new Error();
					error.message = "notFound";
					throw error;
				}
				return response.blob()
			}).then((blob) => {
				const dt = new Date();
				const today = dt.getFullYear() + ('00' + (dt.getMonth()+1)).slice(-2) + ('00' + dt.getDate()).slice(-2);
				const name = "image_"+today+".png";
				const link = document.createElement('a');

				const u = URL ? URL : webkitURL;
				//const isMacOSWebView = window.navigator && /Macintosh/.test(navigator.userAgent) && /AppleWebKit/.test(navigator.userAgent) && !/Safari/.test(navigator.userAgent);				
				let isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);
				//isChromeIOS = true;

				//console.log("userAgent",navigator.userAgent);
				//console.log("isMacOSWebView",isMacOSWebView);

				if('download' in HTMLAnchorElement.prototype && !isChromeIOS){
					const url = u.createObjectURL(new Blob([blob], {type: 'image/png'}));
	
					link.href = url;
					link.setAttribute('download', name);
					link.setAttribute('target', '_blank');
					link.setAttribute('rel', 'noopener noreferrer');
					link.click();

					setTimeout(()=>{
						u.revokeObjectURL(url);
					},1000);
				} else if( isChromeIOS ){
					const reader = new FileReader();
					reader.onload = function () {
						var result = reader.result as string
						var bdata = btoa(result);
						link.href = 'data:image/png;base64,' + bdata;
						link.download = name;
						link.click();	
					};
					reader.readAsBinaryString(blob);
				} else {
					var url = u.createObjectURL(blob)
					var popup = window.open('', '_blank');
					if(popup){
						popup.document.title = popup.document.body.innerText = 'downloading...'	
						popup.location.href = url
						setTimeout(function () { u.revokeObjectURL(url) }, 4E4) // 40s						
					}
				}
				return true;
			}).catch((error)=>{
				console.log(error);
				//return false;
				throw error;
			})
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static createPhrase = async ()=>{
		try {
			return await fetch(Ajax.url+"phrase/create",{
				method: 'POST',
				body: JSON.stringify({Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

}