import React,{useState, useEffect} from 'react';
//import {Redirect} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import WebIcon from '@material-ui/icons/Web';
import PhotoSmallIcon from '@material-ui/icons/Photo';
import PhotoLargeIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PhotoSmallAutoIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import PhotoLargeAutoIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import PhotoSmallSquareIcon from '@material-ui/icons/CropSquareSharp';
import PhotoLargeSquareIcon from '@material-ui/icons/CropDinSharp';
import SettingsIcon from '@material-ui/icons/Settings';
import CopyIcon from '@material-ui/icons/FileCopy';
import TwitterIcon from '@material-ui/icons/Twitter';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import CameraIcon from '@material-ui/icons/CameraAltSharp';
import HelpIcon from '@material-ui/icons/Help';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {UserContainer} from './UserContainer';
import {Stage,StageListType,StageListItems,HelpModeType,Inc} from './Inc';
import CircularProgress from '@material-ui/core/CircularProgress';
import CopyToClipboard from 'react-copy-to-clipboard';
import Toast from './Toast';
import MultiLines from './MultiLines';
import Ajax from './Ajax';
import Help from './Help';

interface StageListProps {
	count:number,
	move(target:string):void,
	edit(id:number):void,
}

const StageList:React.FC<StageListProps> = (props)=> {
	const [states,setStates] = useState({
		stages: Inc.defaultStages(),
		stage: Inc.defaultStage(),
		mode: "updated" as StageListType,
		isStory: false,
		isDeleteStage: false,
		isLoading: true,
		isWaitingImage: false,
		imageURL: "",
		toast: "",
		helpMode: "" as HelpModeType,
	});

	const [anchorUrlMenu, setAnchorUrlMenu] = React.useState<null | HTMLElement>(null);
	const openUrlMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorUrlMenu(event.currentTarget);
	}; 
	const closeUrlMenu = () => {
		setAnchorUrlMenu(null);
	};
	const [anchorCameraMenu, setAnchorCameraMenu] = React.useState<null | HTMLElement>(null);
	const openCameraMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorCameraMenu(event.currentTarget);
	}; 
	const closeCameraMenu = () => {
		setAnchorCameraMenu(null);
	};
 
	const userState = UserContainer.useContainer();

	const load = async (mode:StageListType,force:boolean)=>{
		if(!userState.user){
			return;
		}
		let lastIndex = states.stages["lastIndex"][mode];
		if( force ){
			lastIndex = 0;
		}
		const res = await Ajax.getStages(userState.user.uid,mode,lastIndex);
		if( res.ok ){
			//if( userState.profile.UserId==="" ){
			//	userState.setProfile(res.profile);
			//}
			let stages;
			if( force ){
				stages = Inc.defaultStages();
			} else {
				stages = states.stages;
			}
			if( res.stages ){
				const items = res.stages.length;
				let list = stages[ mode ];
				if( list===null || force ){
					stages[ mode ] = res.stages;
				} else {
					stages[ mode ] = list.concat(res.stages);
				}

				if( items<StageListItems ){
					stages["isEnd"][mode] = true;
				}
				stages["lastIndex"][mode] += items;
			} else {
				stages["isEnd"][mode] = true;
			}

			if(stages[mode]===null){
				stages[mode] = [] as Stage[];
			}

			setStates({...states,
				mode: mode,
				stages: stages,
				isLoading: false,
				isDeleteStage:false,
				isStory: false,
				stage:Inc.defaultStage(),
			});
		}
	}
	const more = ()=>{
		setStates({...states,isLoading:true});
		load(states.mode,false);
	}
	const goEdit = async (event: React.MouseEvent<HTMLElement>)=>{
		if(!userState.user){
			return;
		}
        if( !event.currentTarget.dataset.id ){
            return;
		}
		const id = parseInt(event.currentTarget.dataset.id);
		//const res = await Ajax.getStage(id,userState.user.uid);
		props.edit(id);
	}
	const changeMode = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.mode ){
            return;
		}
		if( event.currentTarget.dataset.mode==="story" ){
			setStates({...states,isStory:true});
		} else {
			const mode = event.currentTarget.dataset.mode as StageListType;
			if( states.stages[mode]===null ){
				setStates({...states,mode:mode,isStory:false,isLoading:true});
				load(mode,false);
			} else {
				setStates({...states,mode:mode,isStory:false});
			}
		}
	}
	const closeToast = ()=>{
		setStates({...states,toast:''});
	}
	const copy = ()=>{
		setStates({...states,toast:"公開ページのURLをクリップボードにコピーしました"});
		closeUrlMenu();
	}
	const configPublic = ()=>{
		closeUrlMenu();
		props.move("code");
	}

	const download = async (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.size ){
            return;
		}
		if(!userState.user){
			return;
		}
		const size = event.currentTarget.dataset.size;
		closeCameraMenu();
		setStates({...states,isWaitingImage:true});
		const res = await Ajax.downloadImage(userState.user.uid,size,(url:string)=>{
			setStates({...states,imageURL:url,isWaitingImage:false});
		});
		if( res ){
			setStates({...states,imageURL:"",isWaitingImage:false});
			//closeCameraMenu();
			//const url = process.env.REACT_APP_VIEW_URL + "tmp/" + res.imageKey + "/image.png";
			//const link = document.createElement('a');
			//link.href = url;
			//link.setAttribute('target', '_blank');
			//link.setAttribute('rel', 'noopener noreferrer');
			//link.setAttribute('download', "image.png");
			//link.click();
		} else {
			setStates({...states,isWaitingImage:false,toast:"ダウンロードできませんでした"});
		}
	}

	const makeDate = (year:string,month:string,day:string,simple:boolean)=>{
		let classWeek = "week";
		let dayOfWeekStr = "";
		let dayStr = "";

		if( year==="9999" ){
			dayStr = "未定";
		} else if( parseInt(month)<13 ){
			if( parseInt(day)<32 ){
				const date = new Date(year+"/"+month+"/"+day);
				let dayOfWeek = date.getDay() ;
				dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek];
				if( dayOfWeek===0 ){
					classWeek = "week weekSun";
				}
				if( dayOfWeek===6 ){
					classWeek = "week weekSat";
				}
				if( year+month+day in Inc.holidays ){
					classWeek = "week weekHol";
				}
				dayStr = month + "." + day;
			} else {
				dayStr = month + Inc.days[day];
			}
		} else {
			dayStr = Inc.months[month];
		}

		if( simple ){
			return (
				<div className="date">
					<span className="date">{dayStr}</span>
					{dayOfWeekStr!=="" &&
					<span className={classWeek}>（{dayOfWeekStr}）</span>
					}
				</div>
			);
		} else if( year!=="9999" ){
			if( parseInt(month)<13 ){
				dayStr = "." + dayStr;
			}
			return (
				<span>
					{year}{dayStr}まで
				</span>
			)
		} else {
			return (
				<span>終了日未定</span>
			)
		}
	}
	const noMore = (list:Stage[] | null)=>{
		if(!list){
			return;
		}
		if( states.isLoading===false && states.stages.isEnd[states.mode]===true && list.length>0 ){
			return (
				<div className="noMore">
					以上！
				</div>
			);
		} else {
			return;
		}
	}
	const renderList = (list:Stage[] | null)=>{
		if(!list){
			return;
		}
		if( list.length===0 ){
			return (
				<div className="noItems">イベントがありません</div>
			);
		}

		let year = "";
		let isNewYear = true;
		return list.map((stage:Stage,index:number)=>{
			const key = "stage"+index;
			let classMethod1 = "method";
			let classMethod2 = "method";
			if( stage.Method1===true ){
				classMethod1 += " method1";
			}
			if( stage.Method2===true ){
				classMethod2 += " method2";
			}

			let mainTitle = stage.Place;
			let subTitle = stage.Title;
			if( userState.profile.Style==="T" ){
				mainTitle = stage.Title;
				subTitle = stage.Place;	
			}
			if( mainTitle==="" ){
				mainTitle = "未定";
			}

			if( year!==stage.Year ){
				year = stage.Year;
				if( year==="9999" ){
					year = "未定";
				}
				isNewYear = true;
			} else {
				isNewYear = false;
			}


			let times = "";
			if( stage.Open!=="" ){
				times = "OPEN " + stage.Open;
			}
			if( stage.Start!=="" ){
				if( times!=="" ){
					times += " / ";
				}
				times += "START " + stage.Start;
			}
				
			return (
				<React.Fragment key={key}>
				{isNewYear &&
				<div className="year">{year}</div>
				}
				<div className="stage">
					<div className="leftSide">
						{makeDate(stage.Year,stage.Month,stage.Day,true)}
						<div className="condition">
							{stage.Condition!=='1' ? (
							<span className="condition">
								{Inc.conditions[ stage.Condition ]}
							</span>
							):(
							<React.Fragment>
							<span className={classMethod1}>来場</span>
							<span className={classMethod2}>配信</span>
							</React.Fragment>
							)}
						</div>
					</div>
					<div className="rightSide">
						<div className="primary">
							<div className="mainTitle">{mainTitle}</div>
							<div className="second">
								{stage.IsSingleDay===true ? (
								<span className="time">{times}</span>
								):(
								<span className="to">{makeDate(stage.YearTo,stage.MonthTo,stage.DayTo,false)}</span>
								)}
							</div>
							{stage.StreamUrl!=="" &&
							<div className="stream_url shortUrl">配信：<a href={stage.StreamUrl} className="link" target="_blank" rel="noopener noreferrer">{stage.StreamUrl}</a></div>
							}
						</div>
						<div className="detail">
							{subTitle!=="" &&
							<div className="subTitle">{subTitle}</div>
							}
							{stage.Artists!=="" &&
							<div className="artists"><MultiLines text={stage.Artists} autolink={true}/></div>
							}
							{stage.Memo!=="" &&
							<div className="memo"><MultiLines text={stage.Memo} autolink={true}/></div>
							}
							<div className="btnDetail">
							{stage.PlaceUrl!=="" &&
								<a href={stage.PlaceUrl} target="_blank" rel="noopener noreferrer">詳しくはこちら</a>
							}
								<div className="actions">
									<Tooltip title="このイベントを修正する">
										<IconButton edge="end" size="small" color="default" aria-label="edit" onClick={goEdit} data-id={stage.Id}>
											<EditIcon color="secondary"/>
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
				</div>
				</React.Fragment>
			)
		})		
	};
	const openUrlHelp = ()=>{
		setAnchorUrlMenu(null);
		setStates({...states,helpMode:"url"});
	}
	const openCameraHelp = ()=>{
		setAnchorCameraMenu(null);
		setStates({...states,helpMode:"camera"});
	}
	const closeHelp = ()=>{
		setStates({...states,helpMode:""});
	}

	useEffect(()=>{
		load(states.mode,true);
	},[props.count]);

	let twitterURL = "";
	let imageURL = Inc.getProfileImageURL(userState.profile);
	const infoURL = process.env.REACT_APP_VIEW_URL + userState.profile.Code;
	const tweetURL = "https://twitter.com/intent/tweet?url="+encodeURIComponent(userState.profile.Name+"の獏と九九\n"+infoURL);

	let viewClass:{[key:string]:string;} = {
		"future": "",
		"past": "",
		"updated": "",
		"story": "",
	}
	if( states.isStory ){
		viewClass["story"] = "active";
	} else {
		viewClass[states.mode] = "active";
	}
	if( userState.profile.TwitterId!=="" ){
		twitterURL = "https://twitter.com/" + userState.profile.TwitterId;
	}
    return (
		<div>
			<div className="profile">
				<div className="image">
					{imageURL !=="" ? (
					<img src={imageURL} alt="イメージ画像" title="イメージ画像"/>
					):(
					<div style={{height:"6em",backgroundColor:"#333366"}}></div>
					)}
					<div className="buttonUrlBack"></div>
					<button className="buttonUrl" onClick={openUrlMenu} style={{position:'absolute'}}>
						<span><ArrowForwardIcon/></span>公開ページ
					</button>
					<Menu
						id="url-menu"
						anchorEl={anchorUrlMenu}
						keepMounted
						open={Boolean(anchorUrlMenu)}
						onClose={closeUrlMenu}
					>
						<a href={infoURL} rel="noopener noreferrer" target="_blank" className="menuLink"><MenuItem onClick={closeUrlMenu}><WebIcon style={{marginRight:"0.25em"}}/> 公開ページへGO！</MenuItem></a>
						<CopyToClipboard text={infoURL} onCopy={copy}>
							<MenuItem>
								<CopyIcon style={{marginRight:"0.25em"}}/> URLをコピー
							</MenuItem>
						</CopyToClipboard>
						<a href={tweetURL} rel="noopener noreferrer" target="_blank" className="menuLink"><MenuItem onClick={closeUrlMenu}><TwitterIcon style={{marginRight:"0.25em"}}/> URLをツイート</MenuItem></a>
						<MenuItem onClick={configPublic}>
							<SettingsIcon style={{marginRight:"0.25em"}}/> 公開の設定
						</MenuItem>
						<Divider style={{margin:"0.25em 0"}}/>
						<MenuItem onClick={openUrlHelp}>
							<HelpIcon style={{marginRight:"0.25em"}}/> ヘルプ
						</MenuItem>
					</Menu>
					<div className="buttonCameraBack"></div>
					{states.isWaitingImage===true ? (
					<div className="isWaiting">
						<CircularProgress color="inherit"/>
					</div>
					):(
					<button className="buttonCamera" onClick={openCameraMenu} style={{position:'absolute'}}>
						<span><CameraIcon/></span>
					</button>
					)}

					<Menu
						id="camera-menu"
						anchorEl={anchorCameraMenu}
						keepMounted
						open={Boolean(anchorCameraMenu)}
						onClose={closeCameraMenu}
					>
						<MenuItem onClick={download} data-size="large"><PhotoLargeIcon style={{marginRight:"0.25em"}}/> 大（幅1200px）</MenuItem>
						<MenuItem onClick={download} data-size="small"><PhotoSmallIcon style={{marginRight:"0.25em"}}/> 小（幅600px）</MenuItem>
						<MenuItem onClick={download} data-size="auto-large"><PhotoLargeAutoIcon style={{marginRight:"0.25em"}}/> 大（幅自動調整）</MenuItem>
						<MenuItem onClick={download} data-size="auto-small"><PhotoSmallAutoIcon style={{marginRight:"0.25em"}}/> 小（幅自動調整）</MenuItem>
						<MenuItem onClick={download} data-size="square-large"><PhotoLargeSquareIcon style={{marginRight:"0.25em"}}/> 大（正方形）</MenuItem>
						<MenuItem onClick={download} data-size="square-small"><PhotoSmallSquareIcon style={{marginRight:"0.25em"}}/> 小（正方形）</MenuItem>
						<Divider style={{margin:"0.25em 0"}}/>
						<MenuItem onClick={openCameraHelp}>
							<HelpIcon style={{marginRight:"0.25em"}}/> ヘルプ
						</MenuItem>
					</Menu>
				</div>
				<div className="text screen">
					<div className="name">{userState.profile.Name}</div>
					<div className="phrase">
						{userState.profile.Phrase !== "" &&
						<span>{userState.profile.Phrase}</span>
						}
						{twitterURL!=="" &&
						<a href={twitterURL} rel="noopener noreferrer" target="_blank"><TwitterIcon className="iconTwitter" color="primary"/></a>
						}
					</div>
					<div className="hp">
						<a href={userState.profile.Url} className="link" target="_blank" rel="noopener noreferrer">{userState.profile.Url}</a>
					</div>
				</div>
			</div>
			<div className="viewModeMenu screen">
				<ul>
					<li data-mode="future" className={viewClass["future"]} onClick={changeMode}>{Inc.viewModes["future"]}</li>
					<li id="tabShort" data-mode="past" className={viewClass["past"]} onClick={changeMode}>{Inc.viewModes["past"]}</li>
					<li id="tabLong" data-mode="story" className={viewClass["story"]} onClick={changeMode}>{Inc.viewModes["story"]}</li>
					<li data-mode="updated" className={viewClass["updated"]} onClick={changeMode}>{Inc.viewModes["updated"]}</li>
				</ul>
			</div>
			{states.isStory===true ? (
			<div className="story screen">
				{userState.profile.Story!=="" ? (
				<div className="inner"><MultiLines text={userState.profile.Story}/></div>
				):(
				<div className="noItems">プロフィールがありません</div>
				)}
			</div>
			):(
			<div className="stages screen">
				{renderList(states.stages[states.mode])}
				{states.isLoading &&
				<div style={{textAlign:"center",padding:"4em 0"}}><CircularProgress color="primary"/></div>
				}
				{states.isLoading===false && states.stages.isEnd[states.mode]===false &&
				<div className="more">
					<Button onClick={more} variant="text" color="default">もっと見る</Button>
				</div>
				}
				{noMore(states.stages[states.mode])}
			</div>
			)}
			<Help close={closeHelp} mode={states.helpMode} isOpen={states.helpMode!==""}/>
			<Toast mes={states.toast} close={closeToast}/>
		</div>
    )
}

export default StageList;
