import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
//import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';
//import HelpIcon from '@material-ui/icons/Help';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MultiLines from './MultiLines';
import {HelpModeType} from './Inc';
//import DialogFooter from './DialogFooter';

interface HelpProps {
	mode:HelpModeType,
    isOpen:boolean,
	close():void,
}

interface HelpData {
	Title: string,
	Answer: string,
}

interface HelpDictionary {
	url: HelpData[],
	camera: HelpData[],
}

const helpDict:HelpDictionary = {
	url: [
		{
			Title:"公開ページって何？",
			Answer:"あなたの出演情報を掲載したページです。今後のイベントと終了したイベント（過去1年以内、100件まで）が表示されます。\n\n誰でも見ることができますので、SNSなどで公開ページのURL（アドレス）をお知らせしてみんなに見てもらいましょう。",
		},
		{
			Title:"公開ページを見られたくない",
			Answer:"「公開の設定」で <b>非公開</b> を選んで下さい。あなたの名前やプロフィールからイベント情報まで全部ひっくるめて非公開になります。",
		},
		{
			Title:"ユーザー名って何？",
			Answer:"公開ページのURL（アドレス）に使われる名前のことです。\n\n最初は自動的にランダムな文字が割り振られますが、いつでも好きなユーザー名に変更することができます。ただし、他の人が使っているユーザー名は使うことができません。\n\nユーザー名に使える文字は、<b>半角英数字とアンダーバー（_）で20文字以内</b>です。",
		},
		{
			Title:"ユーザー名を変更したい",
			Answer:"「公開の設定」でいつでも変更できます。\n\nユーザー名に使える文字は、<b>半角英数字とアンダーバー（_）で20文字以内</b>です。",
		},
		{
			Title:"公開ページに「更新順」がないんですが…",
			Answer:"公開ページには「更新順」はありません。あしからず。",
		},
		{
			Title:"「終了」に表示されないイベントがある",
			Answer:"公開ページの「終了」に表示されるのは、過去1年以内のイベント100件までです。それより古いイベントは表示されません。あしからず。",
		},
		{
			Title:"公開したくないイベントがある",
			Answer:"イベントの「状況」を <b>秘密</b> にすると、公開ページに表示されなくなりますよ。",
		},
		{
			Title:"フリー素材の画像を使いたい",
			Answer:"設定の「イメージ画像」には好きな画像を登録できますが、第三者の著作権および肖像権を損害する画像は使用できません。\n\nフリー素材の場合は利用が可能かどうかを入手元であらかじめご確認下さい。",
		},
		{
			Title:"会場名じゃなくてイベント名を表示したい",
			Answer:"「設定」の <b>表示スタイル</b> で　<b>劇団スタイル（タイトル重視）</b> を選んで下さい。公開ページや出力画像でイベント名が表示されるようになります。",
		},

	],
	camera: [
		{
			Title:"このカメラのマークは何なの？",
			Answer:"これはあなたの出演情報を画像にする機能です。ツイッターなどのSNSに出演情報を手っ取り早くアップしたい場合などにご利用下さい。",
		},
		{
			Title:"大と小で何が違うというのか",
			Answer:"文字の大きさと画像の幅が異なります。大は小の2倍で出力されます。一般に大きな画像の方がSNSなどでもキレイに表示されます。",
		},
		{
			Title:"幅自動調整って何のこと？",
			Answer:"イベント名などの文字数が少ない場合、画像の左右に大きな余白ができます。この余白を無くしたい場合は <b>幅自動調整</b> を選んで下さい。余白を最小限にした画像が作成されます。",
		},
		{
			Title:"画像がダウンロードされない（無反応！）",
			Answer:"ブラウザの種類やバージョンによっては画像が作成されない場合があります。うまくいかない場合はほかのブラウザを試してみてください。\n\n※ブラウザ…　Chrome　Safari　Firefox　Edgeなど",
		},
		{
			Title:"画像がどこにダウンロードされたのかわからない",
			Answer:"ご利用の環境によって異なりますが、「ダウンロード」フォルダを探してみてください。",
		},
		{
			Title:"ダウンロードした画像が謎のファイルになってしまう",
			Answer:"ブラウザの種類やバージョンによっては、ダウンロードした画像が <b>document</b> などの正体不明なファイルになってしまい画像として表示できないことがあります。\n\nファイル名を <b>image.png</b> などに変更すると画像になる場合がありますので、お手数ですが試してみて下さい。",
		},
		{
			Title:"画像の著作権はどうなるのさ",
			Answer:"画像の著作権は作品した本人（あなた）にあるものとします。このサービスの運営者（弊社）が著作権を主張することはありません。",
		},
		{
			Title:"出演者や補足も表示したい",
			Answer:"画像には <b>出演者</b> や <b>補足</b> は表示できません。あしからず。",
		},
		{
			Title:"会場名じゃなくてイベント名を表示したい",
			Answer:"「設定」の <b>表示スタイル</b> で　<b>劇団スタイル（タイトル重視）</b> を選んで下さい。公開ページや出力画像でイベント名が表示されるようになります。",
		},



	],
}

const Help:React.FC<HelpProps> = (props)=> {
	const [helpIndex,setHelpIndex] = useState(-1);

	const putHelpIndex = ()=>{
		if( props.mode==="" ){
			return;
		}
		return helpDict[ props.mode ].map((value:HelpData,index:number)=>{
			const key = "help" + index;
			return (
				<ListItem button key={key} data-index={index} onClick={seeDetail}>
					<ChatIcon style={{marginRight:"0.5em"}}/>
					<ListItemText primary={value.Title} style={{color:"#000000"}}/>
				</ListItem>
			)
		})
	}
	const putHelpDetail = ()=>{
		if( props.mode==="" || helpIndex===-1 || helpDict[props.mode].length<helpIndex ){
			return;
		}
		const title = helpDict[props.mode][helpIndex].Title;
		const answer = helpDict[props.mode][helpIndex].Answer;
		return (
			<List
				component="nav"
				aria-labelledby="detail"
			>
				<ListItem style={{border:"1px solid #E5E5E5",backgroundColor:"#F5F5F5"}}>
					<ChatIcon style={{marginRight:"0.5em"}}/>
					<ListItemText primary={title} style={{color:"#000000"}}/>
				</ListItem>
				<div style={{padding:"0.5em",marginTop:"0.5em",color:"#000000",lineHeight:"1.8em"}}>
					<MultiLines text={answer}/>
				</div>
			</List>
		)
	}

	const seeDetail = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.index ){
            return;
		}
		try {
			const index = parseInt(event.currentTarget.dataset.index);
			setHelpIndex(index);
		} catch( e ){
			console.log(e);
		}
	}

	const closeDetail = ()=>{
		setHelpIndex(-1);
	}

	const close = ()=>{
		setHelpIndex(-1);
		props.close();
	}

	if( !props.isOpen ){
        return (null);
    }

	return (
  		<Dialog
	        open={props.isOpen}
    	    onClose={close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
			<DialogContent>
				<DialogContentText>
					{helpIndex === -1 ? (
					<List
						component="nav"
						aria-labelledby="list-subheader"					
						subheader = {
							<ListSubheader component="div" id="nested-list-subheader">ヘルプ</ListSubheader>
						}
					>
					{putHelpIndex()}
					</List>
					):(
					<React.Fragment>
					{putHelpDetail()}
					</React.Fragment>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
				<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={close}><CancelIcon/></IconButton></Tooltip>
			</DialogActions>
			{helpIndex > -1 &&
			<DialogActions>
		        <Button onClick={closeDetail} color="primary">
        		    <ArrowBackIcon style={{marginRight:"0.25em"}}/>もどる
          		</Button>				
			</DialogActions>
			}
		</Dialog>
    );
}

export default Help;