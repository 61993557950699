import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import PageviewIcon from '@material-ui/icons/Pageview';

interface SampleProps {
    open:boolean,
	close():void,
}

const Sample:React.FC<SampleProps> = (props)=> {
	const close = ()=>{
		props.close();
	}

	if( !props.open ){
        return (null);
    }

	return (
  		<Dialog
	        open={props.open}
    	    onClose={close}
	        aria-labelledby="alert-dialog-title"
    	    aria-describedby="alert-dialog-description"
      	>
			<DialogTitle id="alert-dialog-title" className="dialogTitle"><PageviewIcon style={{verticalAlign:"middle",marginRight:"0.25em"}}/>サンプル</DialogTitle>
			<DialogContent>
				<DialogContentText>
					２つのスタイルのサンプルを用意しました。気になる方からご覧ください。
					<List className="samples">
						<a href="https://tapir99.info/kijitora" target="_blank" rel="noopener noreferrer"><ListItem><FormControl fullWidth={true}><Button variant="outlined" color="primary">キジトラ楽団（バンドスタイル）</Button></FormControl></ListItem></a>
						<a href="https://tapir99.info/moja2021" target="_blank" rel="noopener noreferrer"><ListItem><FormControl fullWidth={true}><Button variant="outlined" color="secondary">峠のモジャ（劇団スタイル）</Button></FormControl></ListItem></a>
					</List>
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
				<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={close}><CancelIcon/></IconButton></Tooltip>
			</DialogActions>
		</Dialog>
    );
}

export default Sample;