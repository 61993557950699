import React,{useState, useEffect} from 'react';
//import {Link} from 'react-router-dom'
import {useTheme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
//import InputAdornment from '@material-ui/core/InputAdornment';
//import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
//import Select from '@material-ui/core/Select';
//import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import {Inc} from './Inc';
import Toast from './Toast';
import Ajax from './Ajax';
import Loading from './Loading';
import Tools from './Tools';
import {UserContainer} from './UserContainer';
//import PhotoUpload from './PhotoUpload';

interface PublishProps {
	open:boolean,
	code:string,
	status:string,
	update(code:string,status:string):void,
	close():void,
}

const CodeInput:React.FC<PublishProps> = (props)=> {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userState = UserContainer.useContainer();
	const [states, setStates] = useState({
		code: props.code,
		status: props.status,
		error: "",
		isLoading: true,
		updated:false,
		toast:'',
	});

	const onChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			code:event.target.value,
			updated:true,
		});
	};
	const onChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		setStates({
			...states,
			status:event.target.value,
			updated:true,
		});
	};
	const validate = async ()=>{
		if(!userState.user){
			return;
		}
		let error = "";
		const Code = states.code.trim();
		const treated = Tools.toHan(Code);
		if(!Tools.validCode(treated)){
			error = "半角英数字とアンダーバーだけ使って5文字以上"+Inc.profileLength["Code"]+"文字以内で入力してください";
		}
		if( error==="" ){
			setStates({...states,code:treated,isLoading:true});
			const res = await Ajax.updateCode(userState.user.uid,Code,states.status);
			if( res.ok ){
				props.update(treated,states.status);
				//setStates({...states,toast:'更新しました',error:error,isLoading:false,updated:false,defaultViewUrl:process.env.REACT_APP_VIEW_URL+states.profile.Code});
			} else {
				let reason = "更新できませんでした";
				if( res.reason && res.reason==="used" ){
					reason = "このユーザー名はほかのユーザーが使用しています";
				}
				setStates({...states,toast:reason,error:error,isLoading:false});
			}
		} else {
			setStates({...states,code:treated,error:error});			
		}
	}
	const closeToast = ()=>{
		setStates({...states,toast:''});
	}
	useEffect(()=>{
		if( props.open && userState.user ){
			//load(userState.user.uid);
			setStates({...states,code:props.code,status:props.status,isLoading:false,toast:"",updated:false,error:""});
		}
	},[props.open]);

	let updateButtonColor:"default"|"secondary" = "default";
	if( states.updated ){
		updateButtonColor = "secondary";
	}

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={props.close}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth="sm"
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
        >
			<DialogTitle>公開設定</DialogTitle>
			<DialogContent style={{paddingBottom:"3.5em"}}>
				<Grid container spacing={1}>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						<TextField
							label="ユーザー名"
							variant="outlined"
							fullWidth={true}
							onChange = {onChangeCode}
							value={states.code}
							error={states.error!==""}
							helperText={states.error}
							type="text"
						/>
					</Grid>
					<Grid item xs={12} style={{marginBottom:"0.3em"}}>
						<FormControlLabel
							control={
								<Radio
									onChange={onChangeStatus}
									value="0"
									name="radio-status"
									color="secondary"
								/>
							}
							
							checked={states.status==="0"}
							label="非公開"
						/>
						<FormControlLabel
							control={
								<Radio
									onChange={onChangeStatus}
									value="1"
									name="radio-status"
									color="primary"
								/>
							}
							
							checked={states.status==="1"}
							label="公開"
						/>
					</Grid>
				</Grid>

			</DialogContent>
			<DialogActions style={{position:"absolute",top:"0",right:"0"}}>
				<Tooltip title="閉じる"><IconButton size="small" color="default" onClick={props.close}><CancelIcon/></IconButton></Tooltip>
			</DialogActions>
			<DialogActions>
				<Button variant="contained" color={updateButtonColor} onClick={validate} disabled={!states.updated}>更新</Button>
			</DialogActions>
			<Toast mes={states.toast} close={closeToast}/>
			<Loading isLoading={states.isLoading}/>
		</Dialog>
    )
}

export default CodeInput;
