import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
//import PersonIcon from '@material-ui/icons/Person';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
//import AppBar from '@material-ui/core/AppBar';
import {UserContainer} from './UserContainer';
import Toolbar from '@material-ui/core/Toolbar';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface HeaderProps {
	menu(open:boolean):void,
	move(target:string):void,
}

const Header:React.FC<HeaderProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const goSettings = ()=>{
		props.move('settings');
	}
	const goInput = ()=>{
		props.move('input');
	}
	const openDrawer = ()=>{
		props.menu(true);
	}
	return (
		<Toolbar variant="dense">
			<IconButton edge="start" color="secondary" style={{display:'flex'}} onClick={openDrawer}><MenuIcon fontSize="large"/></IconButton>
			<Typography variant="h6" style={{textAlign:'center',display:'flex'}}>獏と九九</Typography>
			<div style={{flexGrow:1}}></div>
			{userState.user &&
			<React.Fragment>
			<IconButton edge="end" color="secondary" style={{display:'flex'}} onClick={goSettings}><SettingsIcon fontSize="large"/></IconButton>
			<Hidden xsDown>
				<IconButton edge="end" color="secondary" style={{display:'flex'}} onClick={goInput}><AddBoxIcon fontSize="large"/></IconButton>
			</Hidden>
			</React.Fragment>
			}
		</Toolbar>
    );
}

export default Header;
