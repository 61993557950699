import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import CircularProgress from '@material-ui/core/CircularProgress';
import YesNo from './YesNo';


interface PhotosUploadProps {
  name: string;
  componentRef?: (instance: HTMLInputElement | null) => void;
  photo: string;
  source?: string;
  setPhoto: (photo: string) => void;
}

const PhotosUpload: React.FC<PhotosUploadProps> = ({
  name,
  componentRef,
  photo,
  source,
  setPhoto,
}: PhotosUploadProps): React.ReactElement => {
	const [states,setStates] = useState({
		isLoading: false,
		isFileTypeError: false,
		isYesNo: false,
		deleted: false,
	});

	//const resetErrors = () => {
	//	setStates({...states,isFileTypeError:false,isYesNo:false});
	//};

	const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files === null || event.target.files.length === 0) {
			return;
		}
		setStates({...states,isFileTypeError:false,isLoading:true});
		const files = Object.values(event.target.files).concat();

		event.target.value = "";
		//resetErrors();

		for( let i=0; i<files.length; i++ ){
			if (
				[
					"image/gif",
					"image/jpeg",
					"image/png",
					"image/bmp",
					"image/svg+xml",
				].includes(files[i].type)
			) {
				file2string(files[i]);
			} else {
				setStates({...states,isLoading:false,isFileTypeError:true});
			}
		}
	};

	const file2string = async (file:File)=>{
		const reader = new FileReader()
		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onload = () => {
			const base64Str = reader.result
			if( base64Str ){
				setStates({...states,isFileTypeError:false,isLoading:false});
				setPhoto(base64Str.toString());
			}
		}
		const compressOptions = {
			maxSizeMB: 3,
			maxWidthOrHeight: 1600,
			fileType: "png",
		};
		const compressedFile = await imageCompression(file, compressOptions);
		reader.readAsDataURL(compressedFile);	  
	}

	const handleCancel = () => {
		setStates({...states,isYesNo:true});
	};

	const removeImage = ()=>{
		//resetErrors();
		setStates({...states,isFileTypeError:false,isYesNo:false,deleted:true});
		setPhoto("deleted");
	}
	const closeYesNo = ()=>{
		setStates({...states,isYesNo:false});
	}

	if( states.isLoading ){
		return <CircularProgress />
	}

	let src = "";
	if( photo!=="" && photo!=="deleted" ){
		src = photo;
	} else if( source && source!=="" && !states.deleted ){
		src = source;
	}

  	return (
		<>
			<div>
			{src!=="" ? (
				<button
					type="button"
					style={{borderWidth:"0",borderRadius:"0",boxShadow:"none",margin:"0",padding:"0"}}
					onClick={() => handleCancel()}
				>
					<img
						src={src}
						style={{maxWidth:"100%"}}
						alt="image"
					/>
				</button>
			):(
				<label htmlFor={name}>
					<p style={{padding:"0",margin:"0",marginBottom:"1em",color:"#666666"}}>イメージ画像</p>
					<input
					type="file"
					name={name}
					id={name}
					ref={componentRef}
					accept="image/*"
					onChange={handleFile}
					/>
				</label>  
			)}
			{states.isFileTypeError && (
				<p style={{color:"#FF0000"}}>※jpeg, png, bmp, gif, svg以外のファイル形式は表示されません</p>
			)}
			</div>
			<YesNo onYes={removeImage} onNo={closeYesNo} title="イメージ画像の削除" text="選択した画像を消してよろしいですか？" isOpen={states.isYesNo}/>
		</>
	);
};

export default PhotosUpload;
